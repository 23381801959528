import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { List, Button, Modal, Input, Divider, message } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { OrderStatus, setOrders } from "../../Includes/NavSlice";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "../../Utils";
import { Counter } from "../../Components";

const renderOrderStatus = (orderStatus) => {
    const statusToColor = {
		[OrderStatus.NEW]: "alert-info",
		[OrderStatus.COOKING]: "alert-warning",
		[OrderStatus.AT_STORE]: "alert-warning",
		[OrderStatus.COURIER_PICKED_UP]: "alert-warning",
		[OrderStatus.STORE_READY_FOR_PICKUP]: "alert-info",
		[OrderStatus.COURIER_READY_FOR_PICKUP]: "alert-info",
		[OrderStatus.STORE_COMPLETED]: "alert-success",
		[OrderStatus.COURIER_COMPLETED]: "alert-success",
		[OrderStatus.STORE_ACCEPTED]: "alert-info",
		[OrderStatus.COURIER_ACCEPTED]: "alert-info",
		[OrderStatus.STORE_DECLINED]: "alert-danger",
		[OrderStatus.COURIER_DECLINED]: "alert-danger",
		[OrderStatus.PAYMENT_DECLINED]: "alert-warning",
		[OrderStatus.CANCELLED]: "alert-danger",
		[OrderStatus.PENDING]: "alert-info",
    };
	const el = statusToColor[OrderStatus[orderStatus]];

	return <span className={`${el} orderstatus rounded-pill`}>{OrderStatus[orderStatus]}</span>;
};

const NewOrders = ({pcount, onPostResponse, initOrders, params, onPostSwitch}) => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [pauseResume, setPauseResume] = useState('1');
	const [storeParams, setStoreParams] = useState(params);
	const [storeLocation, setStoreLocation] = useState('');
	const [orders, setInitOrders] = useState([]);
	const [storeId, setStoreId] = useState('');
	const [email, setEmail] = useState('');
	const [locationId, setLocationId] = useState('');
	const [pauseModalVisible, setPauseModalVisible] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
	initOrders = typeof initOrders === "object" ? initOrders : JSON.parse(initOrders);
	
	const options = storeParams?.locations.map(location => ({
		storeSwitch: location.storeSwitch === '1' ? 'ACTIVE' : 'PAUSED',
		value: location.storeId+'|'+location.storeSwitch,
		label: location.storeAddress+', '+location.storeCity+', '+location.storeState
	}));
	
	useEffect(() => 
	{		
		const storeId = localStorage.getItem('userstoreId');
		const email = localStorage.getItem('useremail');
		setStoreId(storeId);
		setEmail(email);
	}, []);
	
	useEffect(() => 
	{
	  if (localStorage.getItem('userstoreId')) {
		const interval = setTimeout(() => {
			let orders = [];
			if(initOrders[0]?.storeId){
				dispatch(setOrders(initOrders));			
				orders = initOrders.map((order, i) => {
					let index = i+1;
					return { ...order, index };
				});
				setInitOrders(orders);
			}
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [initOrders]);
	
	useEffect(() => 
	{
		let orderlen = orders.length;
		for(var i=0; i<orderlen; i++){
			window["update"+orders[i]?.orderId] = false;		
		}
	},[]);
	
	useEffect(() => 
	{		
		if (localStorage.getItem('userstoreId')) {
			const interval = setTimeout(() => {
				setStoreParams(params);
			}, 100);
			return () => clearTimeout(interval); 
		}
	}, [params]);
	
	const [update, setUpdate] = useState(false);
	const [reason, setReason] = useState('');
	
	const postResponse = (response, orderId, locationId) => {
		setUpdate(false);
		
		if(response === 'accept'){
			var output = {
				'rtype': 'updateresponse',
				'action': response,
				'email': email,
				'orderId': orderId,
				'hqId': storeId,
				'storeId': locationId,
				'reason': '',
			}
			onPostResponse(output);	
		}else if(response === 'reason'){
			if(update){
				window["update"+update] = false;
			}
			window["update"+orderId] = true;
			setUpdate(orderId);
		}else{
			if(!reason?.declineReason){
				message.error('Please enter reason for decline');
				return;
			}
			if(window.confirm('Are you sure you want to decline?')) {
				// Delete it!		  
				var output = {
					'rtype': 'updateresponse',
					'action': response,
					'email': email,
					'orderId': orderId,
					'hqId': storeId,
					'storeId': locationId,
					'reason': reason?.declineReason,
				};
				onPostResponse(output);		  
			}
		}
	}
	
	const handleDecline = (order, locationId) => {
		setLocationId(locationId);
		setSelectedOrder(order);
		setModalVisible(true);
		if(reason?.declineReason && reason?.selectedOrder && reason?.selectedOrder === order){
			setReason({selectedOrder: order, declineReason: reason?.declineReason});
		}		
	};
	
	const handleModalCancel = () => {
		setModalVisible(false);
		setSelectedOrder(null);
		setReason({});
	};
	
	const handleChangeDeclineReason = (e) => {
		const value = e.target.value;
		const entry = { selectedOrder: selectedOrder, declineReason: value };
		setReason(entry);
	};
  
	const handleModalOk = () => {	
		setModalVisible(false);
		setSelectedOrder(null);
		postResponse('decline', reason?.selectedOrder, locationId);
	};
  
	const SubAddons = ({ item, i }) => 
	{
		if(i === 0){
			return(
				<>
					<div className="price mb-2" style={{fontSize: 14}}><u>{`Addons`}</u></div>
					<div className="order-item-addons">{`${item}`} </div>
				</>
			)
		}else{
			return(
				<>
					<div className="order-item-addons">{`${item}`} </div>
				</>
			)	
		}
	}
	
	const SubNewOrders = ({ item, index }) => 
	{
		return(
			<>
			<div className="price mb-2 order-item-name" style={{color: '#000000'}}>{`${item?.itemqty}x - ${item?.itemname}`} </div>
			
			{item?.itemaddons.map((nitem, i) => {
				return <SubAddons item={nitem} i={i} key={uuidv4()} />
			})}
			</>
		)
	}
	
	const NewOrders = ({ item, index }) => 
	{		
		try{
			if(item?.storestatus === 'NEW')
			{				
				return (
					<>
					  <div className="col-md-6 col-sm-6 col-lg-3 mb-5 table-lg" key={uuidv4()} >
						<div className="card card-product-grid shadow-sm">
						
						  <div className="order">						  
							  <div className="order-details">
								<div className="order-number">{`# ${item?.orderId}`}</div>
								
								<div className="price mb-2 order-item-name" style={{color: '#000', textDecoration: 'underline'}}>{`${item?.storeaddress}`}</div>
								
								<div className="order-ready-time">Number of Items: <span style={{fontWeight: 600}}>{`${item?.itemcount}`}</span></div>								
								{item?.data.map((nitem, ni) => {
									return <SubNewOrders item={nitem} index={ni} key={uuidv4()} />
								})}
								<div className="mb-4" style={{fontSize: 16, color: '#001529', fontWeight: 600, marginTop: 20, opacity: 0.6}}>
									Total: {`$${(parseFloat(item?.subtotal) / 100).toFixed(2)}`}
							  </div>
							  
							  <div className="order-actions">
								  <Button type="primary" className="" onClick={() => postResponse('accept', item?.orderId, item?.storeId)} style={{width: '40%', height: '40px'}}>
									<b>ACCEPT</b>
								  </Button>
								  <Button onClick={() => handleDecline(item?.orderId, item?.storeId)} style={{width: '40%', height: '40px'}} danger>
									<b>DECLINE</b>
								  </Button>							  
							  </div> 						  
							</div>						  
						  </div>										
						</div>
					  </div>
					</>
				)
			}
			else
			{
				return null;
			}
		}catch(e){}
	};
	
	const ActiveOrders = ({ item, index }) => 
	{		
		if(index === 0){}
		try{
			if(item?.storestatus !== 'STORE_COMPLETED' && item?.storestatus !== 'NEW' && item?.storestatus !== 'STORE_DECLINED')
			{
				return (
					<>
					  <div className="col-md-6 col-sm-6 col-lg-3 mb-5" key={uuidv4()}>
						<div className="card card-product-grid shadow-sm">
						  <div className="info-wrap">
							<div className="price mb-2">
								<div className="order-number">{`# ${item?.orderId}`}</div>
							</div>
							
							<div className="price mb-2 order-item-name" style={{color: '#000', textDecoration: 'underline'}}>{`${item?.storeaddress}`}</div>
							
							<div className="mb-4">
								Number of Items: {`${item?.itemcount}`}
							</div>
							
							{item?.data.map((nitem, ni) => {
								return <SubNewOrders item={nitem} index={ni} key={uuidv4()} />
							})}						
						  </div>
						  <div className="info-wrap">
							<div className="price mb-2">
								Status: {renderOrderStatus(item?.storestatus)}
							</div>
						  </div>
						  <div className="order-actions">
							  <Button type="primary" className="btn-primary" onClick={() => navigate(`/order/${item?.orderId}`, {replace: true})} style={{width: '100%', height: '40px'}}>
								<b>VIEW ORDER</b>
							  </Button>						  
						  </div> 
						</div>
					  </div>
					</>
				)				
				
			}
			else
			{
				return null;
			}
		}catch(e){}
	};
  	
	const getButton = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handleModalCancel}
		  key={uuidv4()}
		>
		  CLOSE
		</Button>,
		<Button
		  size={size}
		  type="primary"
		  onClick={()=> handleModalOk()}
		  key={uuidv4()}
		  danger
		>
		  DECLINE
		</Button>,
	  ];
    };
	
	const getPauseButton = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handlePauseModalCancel}
		  key={uuidv4()}
		>
		  CLOSE
		</Button>,
		<Button
		  size={size}
		  type="primary"
		  onClick={()=> handlePauseModalOk()}
		  key={uuidv4()}
		  danger
		>
		  PAUSE
		</Button>,
	  ];
    };
	
	const getResumeButton = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handlePauseModalCancel}
		  key={uuidv4()}
		>
		  CLOSE
		</Button>,
		<Button
		  size={size}
		  type="primary"
		  onClick={()=> handlePauseModalOk()}
		  key={uuidv4()}
		>
		  RESUME
		</Button>,
	  ];
    };
	
	const pauseOrder = () =>{
		setPauseModalVisible(true);
	}
	
	const handlePauseModalCancel = () => {
		setPauseModalVisible(false);
	};
	
	const handleStoreLocation = (val) => {
		const [value, state] = val.split('|');
		setStoreLocation(val);
		setPauseResume(state);
	};
	
	const handlePauseModalOk = () => {
		let output, msg;
		const [locationId, state] = storeLocation.split('|');
		if(!locationId){
			msg = 'Select store location';
			message.error(msg);
			return false;
		}			
		output = {
			'rtype': 'switchorders',
			'storeId': storeId,
			'locationId': locationId,
			'action': pauseResume === '1' ? 'pause' : 'resume',
			'email': email,
		};
		setPauseModalVisible(false);
		onPostSwitch(output);
	};	

	
  return (
    <>
    <section className="content-main">
		<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
		<div className="content-header1">			
			<div><div onClick={()=> pauseOrder(storeId)} className="btn btn-primary">Resume-Pause Order</div>
			</div>
    </div>
		
		<Counter pcount={pcount} />
	  </div>
	  <div>
        <div className="content-header">
			<h2 className="content-title"> New Orders </h2>
        </div>

        <div className="row">
			<>
			{orders.map((order, i) => {		
				return (
					<NewOrders item={order} index={i} key={uuidv4()} />
				)
			})}
			{orders.length === 0 && (<div style={{justifyContent: 'center', padding: '50px 20px', alignItems: 'center', display: 'flex', fontSize: 20, fontWeight: 900, opacity: 0.5}}>NO NEW ORDERS</div>)}
			</>
        </div>
		<Divider />
		<div className="content-header">
			<h2 className="content-title"> Active Orders </h2>
        </div>
		
		<div className="row">
			<>
			{orders.map((order, i) => {		
				return (
					<ActiveOrders item={order} index={i} key={uuidv4()} />
				)
			})}
			{orders.length === 0 && (<div style={{justifyContent: 'center', padding: '50px 20px', alignItems: 'center', display: 'flex', fontSize: 20, fontWeight: 900, opacity: 0.5}}>NO ACTIVE ORDERS</div>)}
			</>
        </div>
	   </div>
	   
	   <div className="order-comment">
		<Modal
			title={`Specify reason for declining order #${selectedOrder}`}
			open={modalVisible}
			footer={getButton()}
			//onOk={handleModalOk}			
			onCancel={handleModalCancel}
			maskClosable={false}
			closable={true}
		>
		<Divider />
		<Input.TextArea
		  placeholder="Enter reason for declining order"
		  value={selectedOrder ? reason.declineReason : ''}
		  onChange={handleChangeDeclineReason}
		/>
		</Modal>		
	  </div>
	  
	  <div className="order-comment">
		<Modal
			title={`Select location`}
			open={pauseModalVisible}
			footer={pauseResume === '1' ? getPauseButton() : getResumeButton()}
			//onOk={handlePauseModalOk}			
			onCancel={handlePauseModalCancel}
			maskClosable={false}
			closable={true}
		>
			<Divider />
			<select className="form-control py-3 tags-editor" name="storeLocation" title="Store Location" id="storeLocation" value={storeLocation} onChange={(e) => handleStoreLocation(e.target.value)}>
				<option key={uuidv4()} value="">Select a location</option>
				<option key={uuidv4()} value="all|0">Resume all locations</option>
				<option key={uuidv4()} value="all|1">Pause all locations</option>
				{options[0]?.value && options.map(option => (
				  <option key={uuidv4()} value={option.value}>{option.storeSwitch} - {option.label}</option>
				))}
			</select>
		</Modal>		
	  </div>
	  
      </section>
    </>
  );
};

export default NewOrders;
