import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { useNavigate } from "react-router-dom";
import { NewOrders } from "../../Components";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";

const NewOrderScreen = ({pcount, setPcount, loaded, initOrders, setFlash, params, update2, setUpdate2}) => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [updateresponse, setUpdateResponse] = useState(false);
	const [email, setEmail] = useState('');
	const [storeParams, setStoreParams] = useState(params);
	const [storeId, setStoreId] = useState('');
	const { Content } = Layout;
	initOrders = typeof initOrders === "object" ? initOrders : JSON.parse(initOrders);
	  
	const breadcrumbItems = [
		{ breadcrumbName: "Orders", title: "Orders" },
		{ breadcrumbName: "New", title: "New" },
	];
	
	useEffect(() => 
	{		
		if (localStorage.getItem('userstoreId')) {
			const interval = setTimeout(() => {
				setStoreParams(params);
			}, 100);
			return () => clearTimeout(interval); 
		}
	}, [params]);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "New Pending Orders | Restaurant | ErrandLocal";
	}, []);
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	const onPostResponse = async (param) =>
	{	
		try{
			setUpdateResponse(false);
			const merchantURL = await utils.MERCHANT_URL;
			const header = await utils.genHeader();
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Token Updated'){
				localStorage.setItem('__el_token', data?.token);
				message.info('Please resubmit');
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){
				setUpdateResponse(true);
				
				let ordersFromServer = await utils.fetchHqIdOrder(storeId);
				let orderlen = ordersFromServer.length;
				const orderData = await utils.cipherEncode(JSON.stringify(ordersFromServer));
				await localStorage.setItem('__el_ord', orderData);
				const orderInfo = await utils.cipherDecode(orderData);
				
				let islen = 0;
				let isaudio = 0;
				let orderstatus , offers; 
				for(let i=0; i<orderlen; i++)
				{
					offers = ordersFromServer[i].data;
					orderstatus = ordersFromServer[i].storestatus;
					
					if(offers.length > 0 && orderstatus === 'NEW')
					{						
						isaudio++;
					}
					
					if(offers.length > 0 && orderstatus !== 'STORE_COMPLETED')
					{
						islen++;
					}
				}
				
				if(isaudio > 0 && islen >= 0)
				{
					//Play Audio
					setPcount(islen);
					setFlash(true);					
				}
				else if(isaudio === 0 && islen > 0)
				{
					//stop audio
					setPcount(islen);
					setFlash(false);
				}
				
				else if(isaudio === 0 && islen === 0)
				{
					//stop audio
					setPcount('');
					setFlash(false);
				}
				else
				{
					//stop audio
					setPcount('');
					setFlash(false);
				}
				
			}
			else
			{
				message.error(data.msg);
			}
		}catch(e){}
	}
	
	const onPostSwitch = async (param) =>
	{	
		try{
			setUpdateResponse(false);
			const merchantURL = await utils.MERCHANT_URL;
			const header = await utils.genHeader();
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Token Updated'){
				localStorage.setItem('__el_token', data?.token);
				message.info('Please resubmit');
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){
				setUpdateResponse(true);
				message.success('Action was successful');	
				setUpdate2(!update2);
			}
			else
			{
				message.error(data.msg);
			}
		}catch(e){}
	}
	
	
	return (
		<Content style={{ margin: "24px 16px 0" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Pending Orders</h2>
			<span style={{ opacity: 0.5 }}>This are the list of pending orders assigned to your store</span>
			<Divider />
			{storeParams?.locations && (<NewOrders pcount={pcount} onPostResponse={onPostResponse} initOrders={initOrders} params={storeParams} onPostSwitch={onPostSwitch} />)}
		  </div>
		</Content>
	);
};

export default NewOrderScreen;
