import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { OrderDetail } from "../../Components";
import { useDispatch } from "react-redux";
import { setOrders } from "../../Includes/NavSlice";
import { utils } from "../../Utils";

const OrderDetailScreen = ({pcount, update}) => {
	
	const { orderId } = useParams();	
	const [orderdata, setOrderData] = useState('');
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Orders", title: "Orders" },
		{ breadcrumbName: "All Orders", title: "All Orders" },
		{ breadcrumbName: "Order Info", title: "Order Info" },
	];
		
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = `Order #${orderId} - Detail | Restaurant | ErrandLocal`;
	}, []);
  
	useEffect(() => {
	  if(update){
		const interval = setTimeout(async() => {
		  try{
		     const data = await fetchOrder();
			 setOrderData(data);
		  }catch(e){			
		     setOrderData({});
		  }
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [update]);
	
	useEffect(() => {
		const interval = setTimeout(async() => {
		  try{
		     const data = await fetchOrder();
			 setOrderData(data);
		  }catch(e){			
		     setOrderData({});
		  }
		}, 100);
		return () => clearTimeout(interval); 	  
	}, []);
	
	
	const fetchOrder = async () => 
	{
		try{
			const target = await utils.MERCHANT_CALL+'?request=order&orderId='+orderId;
			const header = await utils.genHeader();
			const res = await utils.fetchURL(target, 'GET', '', header);
			const data = await res.json();					
			return data;	
		}catch(e){			
			return [];
		}		
	}
	
	
	const onPostStatus = async (param) =>
	{	
		try{
			const target = await utils.MERCHANT_URL;
			const header = await utils.genHeader();
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(target, method, dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Token Updated'){
				localStorage.setItem('__el_token', data?.token);
				message.info('Please resubmit');
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){		
				const data = await fetchOrder();
				setOrderData(data);
			}else{
				message.error(data.msg);
			}
		}catch(e){}
	}
	
	return (
		<Content style={{ margin: "24px 16px 0" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Order Info</h2>
			<span style={{ opacity: 0.5 }}>Information and instruction on order</span>
			<Divider />
			{orderdata && <OrderDetail pcount={pcount} orderdata={orderdata} onPostStatus={onPostStatus} />}
		  </div>
		</Content>
	);

};

export default OrderDetailScreen;
