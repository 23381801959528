import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { Store } from "../../Components";
import { utils } from "../../Utils";

const StoreScreen = ({pcount, params, update2, setUpdate2}) => {
	
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Settings", title: "Settings" },
		{ breadcrumbName: "Manage Store", title: "Manage Store" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Manage Store | Restaurant | ErrandLocal";
	}, []);
		
	const onPostProfile = (param) =>
	{	
		let targeturl = utils.MERCHANT_URL,
		token = localStorage.getItem('__el_token'),
		formdata = new FormData();
				
		Object.entries(param).forEach(entry => {
			let [key, value] = entry;
			if(key === 'imageFile'){
				formdata.append("file0", value[0]);
			}else if(key === 'bgImageFile'){
				formdata.append("file1", value[0]);
			}else{
				formdata.append(key, value);
			}			
		});
		
		let ajax = new XMLHttpRequest();
		ajax.upload.addEventListener("progress", progressHandler, false); 
		ajax.addEventListener("load", completeHandler, false); 
		ajax.addEventListener("error", errorHandler, false); 
		ajax.addEventListener("abort", abortHandler, false); 
		ajax.open("POST", targeturl); 
		ajax.setRequestHeader('Authorization', 'Bearer '+token);
		ajax.send(formdata);
		return false;
	}
	
	function progressHandler(event){ 
        //message.info("Uploaded "+event.loaded+" bytes of "+event.total);
		let percent = (event.loaded / event.total) * 100;
		//message.info(Math.round(percent)+"% uploaded... please wait");
	} 
	function completeHandler(event){	
		const data = JSON.parse(event.target.response);	
		if(data.msg === 'Successful' || data.msg === 'Success'){
			message.success(data.msg);
		}else if(data.msg === 'Token Updated'){
			message.info('Please republish');
			localStorage.setItem('__el_token', data.token);
		}else{
			message.error(data.msg);			
		}
	} 
	function errorHandler(event){ 
		message.error('Upload Failed');
	} 
	function abortHandler(event){ 
		message.error('Upload Aborted');
	} 
	
	const onCloseLocation = async (param) =>
	{	
		try{
			const merchantURL = await utils.MERCHANT_URL;
			const header = await utils.genHeader();
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Token Updated'){
				localStorage.setItem('__el_token', data?.token);
				message.info('Please resubmit');
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){
				message.success('Action was successful');	
				setUpdate2(!update2);
			}
			else
			{
				message.error(data.msg);
			}
		}catch(e){}
	}
	
	return (
		<>
		<Content style={{ margin: "24px 16px 0" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Manage Store</h2>
			<span style={{ opacity: 0.5 }}>Update store information.</span>
			<Divider />
			{params?.categoryOptions && (<Store pcount={pcount} params={params} onPostProfile={onPostProfile} onCloseLocation={onCloseLocation} />)}
		  </div>
		</Content>
		
	</>
	);
	
};

export default StoreScreen;

