import React, {useState, useEffect} from "react";
import { Form, Button, notification, Row, Tag, message } from "antd";
import { useNavigate } from "react-router-dom";
import { utils } from "../../Utils";
import { EditTable } from "./EditTable";
import { SaveOutlined, ReloadOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { Counter } from "../../Components";

let mockData = {
	  itemData: [
		{
		  category: "",
		  file: "",
		  name: "",
		  description: "",
		  price: 0,
		  info: [],
		}
	  ]
};

//const categories = [];


const EditItem = ({itemId, pcount, params, loaded, update, setUpdate, dataFetched, setDataFetched}) => 
{ 

  const navigate = useNavigate();
  //const [email, setEmail] = useState('');
  //const [storeId, setStoreId] = useState('');
	
	let email = localStorage.getItem("useremail");
	email = email ?? '';
	let storeId = localStorage.getItem("userstoreId");
	storeId = storeId ?? '';
	
  const [data, setData] = useState(mockData);
  const [dataLevel, setDataLevel] = useState(params?.itemCategoryOptions ?? []);
  const [info, setInfo] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');
  const [form] = Form.useForm();
  const itemURL = utils.STORE_MENU_URL;
  
  
  useEffect(() => {	 
		setData(mockData);
		window['info'] = [];
  }, []);
	
  useEffect(() => {
		if(update){
			getUpdate(dataFetched);
		}
  }, [update]);
	
	const getUpdate = async(data) =>{
		const info = await utils.convertJsonStr(JSON.stringify(data));
		mockData.itemData[0].category = data?.itemCategoryName;
		mockData.itemData[0].file = data?.itemPhoto;
		mockData.itemData[0].name = data?.itemName;
		mockData.itemData[0].description = data?.itemDescription;
		mockData.itemData[0].price = (parseFloat(data?.price?.amount) / 100).toFixed(2);
		mockData.itemData[0].info = info;
		await moveIt(info);
		setFileName(data?.itemPhoto);
		setData(mockData);
	}
  
  useEffect(() => {
	  if (storeId && storeId !== 'undefined' && itemId && itemId !== 'undefined' && loaded) {
		const interval = setTimeout(() => {
		  fetchItems(itemId, storeId);
		}, 100);
		return () => clearTimeout(interval); 
	  }
  }, [storeId, itemId, loaded]);
  
  
  const moveIt = async (entries) => {
		//await setInfo(entries);
		window['info'] = entries;
  };
  
  const fetchItems = async() => 
  {
		const header = await utils.genHeader();
		//const target = await itemURL+'?request=item&storeId='+storeId+'&itemId='+itemId;
		const target = await itemURL+'?storeId='+storeId+'&itemId='+itemId+'&options=full';
		//console.log(target);
		const res = await utils.fetchURL(target, 'GET', '', header);	
		let data = await res.json();
		if(data?.status === 'Success'){
			data = await utils.cipherDecode(data?.response);
			data = typeof data === 'object' ? data : JSON.parse(data);
		}		
		const info = await utils.convertJsonStr(JSON.stringify(data));
		mockData.itemData[0].category = data?.itemCategoryName;
		mockData.itemData[0].file = data?.itemPhoto;
		mockData.itemData[0].name = data?.itemName;
		mockData.itemData[0].description = data?.itemDescription;
		mockData.itemData[0].price = (parseFloat(data?.price?.amount) / 100).toFixed(2);
		mockData.itemData[0].info = info;
		await moveIt(info);
		setFileName(data?.itemPhoto);
		setData(mockData);
		setUpdate(true);
		setDataFetched(data);
  }

  const onFinish = (values) => {
		const linfo = window['info'];
		values.itemData[0].info = linfo; //info;	
		values.itemData[0].file = fileName;	
    setData(values);
	
	let output = {
		'rtype': 'editproduct',
		'email': email,
		'mainStoreId': storeId,
		'mainItemId': itemId,
		'productCategory': values.itemData[0].category,
		'productName': values.itemData[0].name,		
		'productImage': values.itemData[0].file,
		'productDescription': values.itemData[0].description,
		'productPrice': values.itemData[0].price,
		'productData': values.itemData[0].info		
	}	
	console.log('Output', output);
	return;
		
	let targeturl = utils.MERCHANT_URL, 
	token = localStorage.getItem('__el_token'), 
	formdata = new FormData();
	
	fileList.forEach(function(file, i)
	{
		formdata.append("file"+i, file);
	});
	
	Object.entries(output).forEach(entry => {
		let [key, value] = entry;
		if(key === 'productData'){
			formdata.append(key, JSON.stringify(value));
		}else{
			formdata.append(key, value);
		}
	});
	
	let ajax = new XMLHttpRequest();
	ajax.upload.addEventListener("progress", progressHandler, false); 
	ajax.addEventListener("load", completeHandler, false); 
	ajax.addEventListener("error", errorHandler, false); 
	ajax.addEventListener("abort", abortHandler, false); 
	ajax.open("POST", targeturl); 
	ajax.setRequestHeader('Authorization', 'Bearer '+token);
	ajax.send(formdata);
	return false;    
	
  };
    
  const getFile = async (file) => {
	if(!file){
		await setFileName('');
		await setFileList([]);
		mockData.itemData[0].file = 'File2.jpg';
		await setData(mockData);		
	}else{
		await setFileList([file]);
		await setFileName(file.name);
		mockData.itemData[0].file = file.name;		
		data.itemData[0].file = file.name;
		await setData(mockData);
		await setData(data);
	}
	
  };
  
    function progressHandler(event){ 
        //message.info("Uploaded "+event.loaded+" bytes of "+event.total);
		let percent = (event.loaded / event.total) * 100;
		//message.info(Math.round(percent)+"% uploaded... please wait");
	} 
	function completeHandler(event){	
		const data = JSON.parse(event.target.response);	
		if(data.msg === 'Successful'){
			message.success(data.msg);
		}else if(data.msg === 'Token Updated'){
			message.info('Please republish');
			localStorage.setItem('__el_token', data.token);
		}else{
			message.error(data.msg);			
		}
	} 
	function errorHandler(event){ 
		message.error('Upload Failed');
	} 
	function abortHandler(event){ 
		message.error('Upload Aborted');
	} 

    

  return (
  <section className="content-main">
	<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
		<div className="content-header1">			
			<div><div onClick={()=> navigate("/item-list")} className="btn btn-primary">Back to item list</div></div>
        </div>
		
		<Counter pcount={pcount} />
	</div>
	
  {update && (<Form
      form={form}
      name="dynamic_form_item"
      onFinish={onFinish}
      initialValues={data}
			key={uuidv4()}
    >	  
      
	<>
	  {dataLevel.length >= 0 && loaded && (<Form.List name="itemData">		
		{(itemData, { add, remove }) => (
		  <EditTable
			data={data}
			size={`large`}
			categories={dataLevel}
			form={form}
			itemData={itemData}
			add={add}
			remove={remove}
			moveIt={(val)=> moveIt(val)}
			getFile={(file)=> getFile(file)}
			key={uuidv4()}
		  />
		)}

      </Form.List>)}
      <br />
      <Row>
        <Form.Item key={uuidv4()}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
						className="btn-primary"
            style={{ marginLeft: 8 }}
						size={`large`}
          >
            Publish
          </Button>
        </Form.Item>		
      </Row>
	</>
  </Form>)}
  
	{!update && (<div><br/><h6 style={{fontWeight:900, opacity:0.5}}>LOADING DATA...</h6></div>)}
	
	</section>
  );

};

export default EditItem;
