import React from "react";
import { useNavigate } from "react-router-dom";

const Counter = ({pcount}) => {
  const navigate = useNavigate();
	
  return(
	<div className="content-header1">
	  <div className="btn-icon" onClick={()=> navigate("/new-orders")} style={{cursor: 'pointer'}}>
		  <i className="fas fa-bell" style={{fontSize: 25}}></i>
			{pcount && (<span
				style={{
					position: 'relative',
					top: -35,
					right: 0,
					height: 20,
					width: 20,
					alignItems: 'center',
					justifyContent: 'center',
					borderRadius: 12,
					backgroundColor: '#000000',
					display: 'inline-flex'
				}}
			>
				<span
					style={{
						color: '#ffffff',
						fontSize: 13
					}}
				>
				{pcount}
				</span>
			</span>)}
		</div>
	</div>
	
  );
};

export default Counter;
