import React from "react";
import { Form } from "antd";

const Dummy = props => (
  <div style={{ paddingLeft: 12 }}>{props.value}</div>
);

export const EditFormItem = props => {
  const { editing, ...rest } = props;
  return (
    <Form.Item {...rest}>{editing ? props.children : <Dummy />}</Form.Item>
  );
};
