import React, {useEffect, useState} from "react";
import { v4 as uuidv4 } from "uuid";
import { OrderStatus } from "../../Includes/NavSlice";

const renderOrderStatus = (orderStatus) => {
    const statusToColor = {
		[OrderStatus.NEW]: "alert-info accepted",
		[OrderStatus.COOKING]: "alert-warning warned",
		[OrderStatus.AT_STORE]: "alert-warning warned",
		[OrderStatus.COURIER_PICKED_UP]: "alert-warning warned",
		[OrderStatus.STORE_READY_FOR_PICKUP]: "alert-info accepted",
		[OrderStatus.COURIER_READY_FOR_PICKUP]: "alert-info accepted",
		[OrderStatus.STORE_COMPLETED]: "alert-success succeed",
		[OrderStatus.COURIER_COMPLETED]: "alert-success succeed",
		[OrderStatus.STORE_ACCEPTED]: "alert-info accepted",
		[OrderStatus.COURIER_ACCEPTED]: "alert-info accepted",
		[OrderStatus.STORE_DECLINED]: "alert-danger declined",
		[OrderStatus.COURIER_DECLINED]: "alert-danger declined",
		[OrderStatus.PAYMENT_DECLINED]: "alert-warning warned",
		[OrderStatus.CANCELLED]: "alert-danger declined",
		[OrderStatus.PENDING]: "alert-info accepted",
    };
	const el = statusToColor[OrderStatus[orderStatus]];

	return <span className={`${el} orderstatus rounded-pill`}>{OrderStatus[orderStatus]}</span>;
};

const renderSettlementStatus = (SettlementStatus) => {
    const statusToColor = {
		"0": "alert-info accepted",
		"1": "alert-success succeed",
    };
	const statusToVal = {
		"0": "PENDING",
		"1": "COMPLETED",
    };
	const el = statusToColor[SettlementStatus];

	return <span className={`${el} orderstatus rounded-pill`}>{statusToVal[SettlementStatus]}</span>;
};

const renderOrderType = (stats) => {
    const statusToColor = {
		"Pickup": "alert-danger declined",
		"Delivery": "alert-warning warned",
    };
	const statusToVal = {
		"Pickup": "PICK UP",
		"Delivery": "DELIVERY",
    };
	const el = statusToColor[stats];

	return <span className={`${el} orderstatus rounded-pill`}>{statusToVal[stats]}</span>;
};

const renderOrderAmount = (stats) => {   

	return <span style={{color: "black"}}><b>{stats}</b></span>;
};

const OrderDetailItems = ({orderdata}) => {

	const [itemTotal, setItemTotal] = useState(0);
	let orders = orderdata[0]?.data;
	const subt = parseFloat(orderdata[0]?.subtotal).toFixed(2);
	const refund = parseFloat(orderdata[0]?.refund).toFixed(2);
	let tot = parseFloat(subt) + parseFloat(refund);
	tot = tot.toFixed(2);
	
	useEffect(() => 
	{		
		var total = 0;
		for(let i = 0; i < orders?.length; i++){
			const order = orders[i];
			const qty = order.itemqty;
			const price = order.itemprice;
			total += parseFloat(qty) * parseFloat(price);
			if(i === orders.length - 1){
				total = parseFloat(total / 100).toFixed(2);
				setItemTotal(total);
			}
		}		
	}, []);
	
	
	return (
    <table className="table border table-lg">
      <thead>
        <tr>
          <th style={{ width: "40%" }}>{renderOrderAmount('Product')}</th>
          <th style={{ width: "20%" }}>{renderOrderAmount('Price')}</th>
          <th style={{ width: "20%" }}>{renderOrderAmount('Quantity')}</th>
          <th style={{ width: "20%" }} className="text-end">{renderOrderAmount('Amount')}</th>
        </tr>
      </thead>
      <tbody>
		{orders?.map((order, i) =>  (
        <tr key={uuidv4()}>
          <td>
            <div className="itemside" style={{display: 'flex'}}>
              <div className="left">
                <img
                  src={order?.itemuri}
                  alt="product"
                  style={{ width: 30, height: 30, marginRight: 10 }}
                  className="img-xs"
                />
              </div>
              <div className="info" style={{fontSize: 15}}>
							{renderOrderAmount(order.itemname)}
							<br/>
							{order?.itemaddons?.map(orderaddon =>  (
								<span key={uuidv4()}><font size="2">{orderaddon}</font><br/></span>
							))}
              </div>
            </div>
          </td>
          <td>{`$${parseFloat(order?.itemprice) / 100} `}</td>
          <td>{order?.itemqty} </td>
          <td className="text-end">{` $${parseFloat(order?.itemsubtotal)  / 100}`}</td>
		</tr>
		))}
		
        <tr>
          <td colSpan="4">
            <article className="float-end">
              <dl className="dlist">
                <dt>Subtotal:</dt> <dd>{`$${itemTotal}`}</dd>
              </dl>
              <dl className="dlist">
                <dt>Refund:</dt> <dd>{`$${parseFloat(orderdata[0]?.refund)  / 100}`}</dd>
              </dl>
              <dl className="dlist">
                <dt className="h5">{renderOrderAmount('Total:')}</dt>
                <dd>
                  <b className="h5">{renderOrderAmount(`$${itemTotal}`)}</b>
                </dd>
              </dl>
              <dl className="dlist">
                <dt className="text-muted">Order Status:</dt>
                <dd>
                  <span className="badge rounded-pill alert-success">
                    {renderOrderStatus(orderdata[0]?.storestatus)}
                  </span>
                </dd>
              </dl>
              <dl className="dlist">
                <dt className="text-muted">Settlement:</dt>
                <dd>
                  <span className="badge rounded-pill">
                    {renderSettlementStatus(orderdata[0]?.settlement)}
                  </span>
                </dd>
              </dl>
              <dl className="dlist">
                <dt className="text-muted">Order Type:</dt>
                <dd>
                  <span className="badge rounded-pill">
                    {renderOrderType(orderdata[0]?.deliveryType)}
                  </span>
                </dd>
              </dl>
            </article>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDetailItems;
