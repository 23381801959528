import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { Home } from "../../Components";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";

const HomeScreen = ({pcount, initOrders, params}) => {
	
	initOrders = typeof initOrders === "object" ? initOrders : JSON.parse(initOrders);
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [updatecategory, setUpdateCategory] = useState(false);
	const [filelist, setFileList] = useState([]);
	const merchantCALL = utils.MERCHANT_CALL;
	
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Dashboard", title: "Dashboard" },
	];
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Dashboard | Restaurant | ErrandLocal";
	}, []);
  
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	
	return (
		<>
		<Content style={{ margin: "24px 16px 0" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Welcome!</h2>
			<span style={{ opacity: 0.5 }}>Manage your restaurant.</span>
			<Divider />
			<Home pcount={pcount} initOrders={initOrders} params={params} />
		  </div>
		</Content>
		
	</>
	);
	
};

export default HomeScreen;
