import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	credentials: [],
	products: [],
	orders: [],
	stores: [],
	storecategories: [],
	productcategories: [],
};

export const navSlice = createSlice({ 
	name: "nav",
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			state.credentials = action.payload;
		},
		setProducts: (state, action) => {
			state.products = action.payload;
		},
		setOrders: (state, action) => {
			state.orders = action.payload;
		},
		setStores: (state, action) => {
			state.stores = action.payload;
		},
		setStoreCategories: (state, action) => {
			state.storecategories = action.payload;
		},
		setProductCategories: (state, action) => {
			state.productcategories = action.payload;
		}
	},
});

export const { setCredentials, setProducts, setOrders, setStores, setStoreCategories, setProductCategories } = navSlice.actions;
export const selectCredentials = (state) => state.nav.credentials;
export const selectOrders = (state) => state.nav.orders;
export const selectProducts = (state) => state.nav.products;
export const selectStores = (state) => state.nav.stores;
export const selectStoreCategories = (state) => state.nav.storecategories;
export const selectProductCategories = (state) => state.nav.productcategories;
export const OrderStatus = {
	"NEW": "NEW",
	"COOKING": "COOKING",	
	"AT_STORE": "AT_STORE",
	"COURIER_PICKED_UP": "PICKED_UP",
	"STORE_READY_FOR_PICKUP": "READY_FOR_PICKUP",
	"COURIER_READY_FOR_PICKUP": "READY_FOR_PICKUP",
	"STORE_COMPLETED": "COMPLETED",
	"COURIER_COMPLETED": "COMPLETED",
	"STORE_ACCEPTED": "ACCEPTED",
	"COURIER_ACCEPTED": "ACCEPTED",
	"STORE_DECLINED": "DECLINED",
	"COURIER_DECLINED": "DECLINED",
	"CANCELLED": "CANCELLED",
	"PENDING": "PENDING",
	"PAYMENT_DECLINED": "PAYMENT_DECLINED",
};
export default navSlice.reducer;
