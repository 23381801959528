import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { selectCredentials } from "../../Includes/NavSlice";
import { utils } from "../../Utils";
import { useSelector } from "react-redux";
import { Counter } from "../../Components";
import InputMask from "react-input-mask";

const CardMain = ({onPostCard, onPostGetCode, update}) => {
	
	const [cardType, setCardType] = useState("");	
	const [cardNumber, setCardNumber] = useState("");
	const [cardName, setCardName] = useState("");
	const [expiryDate, setExpiryDate] = useState("");
	const [cvv, setCvv] = useState("");
	const [cardZip, setCardZip] = useState("");
	const [emailCode, setEmailCode] = useState('');
	const [emailSent, setEmailSent] = useState(false);	
	
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	
	const [primaryPhone, setPrimaryPhone] = useState('');
	const [secondaryPhone, setSecondaryPhone] = useState('');
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	
	useEffect(() => 
	{
		if(update){
			setEmailSent(true);
		}
		// eslint-disable-next-line
	}, [update]);
	
	const onGetCode = (e) => 
	{
		e.preventDefault();
		var output;
		
		output = {
			'rtype': 'getcode',
			'storeId': storeId,
			'email': email,
		}
		onPostGetCode(output);		
	}
	
	
	const onSubmitCard = (e) => 
	{
		e.preventDefault();
		var cardType, cardName, cardNumber, cvv, expiryDate, cardZip, emailCode, output, msg;
		
		cardType = document.getElementById('cardType').value;
		cardNumber = document.getElementById('cardNumber').value;
		cardName = document.getElementById('cardName').value;
		cvv = document.getElementById('cvv').value;
		expiryDate = document.getElementById('expiryDate').value;
		cardZip = document.getElementById('cardZip').value;
		emailCode = document.getElementById('emailCode').value;
		
		if (!cardType) {
			msg = 'Select card type';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!cardNumber) {
			msg = 'Enter card number';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if(!cardName) {
			msg = 'Enter card name';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if(!cvv) {
			msg = 'Enter card cvv';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!expiryDate) {
			msg = 'Enter card expiry date';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!cardZip) {
			msg = 'Enter card zip';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!emailCode && emailSent) {
			msg = 'Enter email code';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!emailCode && !emailSent) {
			msg = 'Get email code first';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}		
		
		output = {
			'rtype': 'addcard',
			'storeId': storeId,
			'cardno': cardNumber, 
			'cardname': cardName, 
			'cardzip': cardZip, 
			'cardexp': expiryDate, 
			'cardcvv': cvv,
			'cardtype': cardType,
			'emailcode': emailCode,
			'email': email,
		}
		onPostCard(output);	
	}
	
	return (
		<>	
			<br/><br/>
			<div className="mb-4">
				<label htmlFor="cardType" className="form-label">
					<b>Card Type</b> *
				</label>				
				<select className="form-control py-3 tags-editor" name="cardType" title="Card Type" id="cardType" value={cardType} onChange={(e) => setCardType(e.target.value)}>
					<option value="">Select Card Type</option>
					<option value="Mastercard">Mastercard</option>
					<option value="Visa">Visa</option>
				</select>
			</div>
			
			<div className="mb-4">
				<label htmlFor="cardNumber" className="form-label">
					<b>Card Number</b> *
				</label>
				<input className="form-control py-3 tags-editor" type="text" name="cardNumber" id="cardNumber" placeholder="Card Number" title="Card Number" maxLength="16" value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} />
			</div>	
			
			<div className="mb-4">
				<label htmlFor="cardName" className="form-label">
					<b>Card Name</b> *
				</label>
				<input className="form-control py-3 tags-editor" type="text" name="bankName" id="cardName" placeholder="Card Name" title="Card Name" value={cardName} onChange={(e) => setCardName(e.target.value)} />
			</div>

			<div style={{'display': 'flex'}}>
				<div className="mb-4 fullhalf">				
					<label htmlFor="expiryDate" className="form-label">
					<b>Expiry</b> *
					</label>					
					<InputMask
					mask={`99/9999`}
					id="expiryDate"
					placeholder={`MM/YYYY`}
					className="form-control py-3 full tags-editor"
					autoComplete="off"
					value={expiryDate}
					onChange={(e) => setExpiryDate(e.target.value)}
					/>
				</div>	
				
				<div className="mb-4 fullhalf-right">					
					<label htmlFor="cvv" className="form-label">
					<b>Card CVV</b> *
					</label><input className="form-control py-3 full tags-editor" maxLength="3" type="text" name="cvv" id="cvv" placeholder="Card CVV" title="Card CVV" value={cvv} onChange={(e) => setCvv(e.target.value)} />
				</div>
			</div>			
			
			<div style={{'display': 'flex'}}>
				<div className="mb-4 fullhalf">
					<label htmlFor="cardZip" className="form-label">
					<b>Card Zip</b> *
					</label><input className="form-control py-3 full tags-editor" maxLength="7" type="text" name="cardZip" id="cardZip" placeholder="Card Zip" title="Card Zip" value={cardZip} onChange={(e) => setCardZip(e.target.value)} />					
				</div>	
				
				<div className="mb-4 fullhalf-right">
					<label htmlFor="emailCode" className="form-label">
					<b>Email Code</b> *
					</label><input className="form-control py-3 full tags-editor" type="text" name="emailCode" id="emailCode" placeholder="Email Code" title="Email Code" value={emailCode} onChange={(e) => setEmailCode(e.target.value)} />
				</div>
			</div>
			
			<div style={{'display': 'flex'}}>
				<div className="mb-4 fullhalf">				
					<label htmlFor="getCode" className="form-label">
					
					</label>
					{!emailSent && (<button id="getCode" className="btn btn-primary py-3 mt-4" onClick={onGetCode}>
						Get Code
					</button>)}
				</div>	
				
				<div className="mb-4 fullhalf-right">				
					
				</div>
			</div>
					
			<div className="incsizespan center mb-2" id="feedback">
				
			</div>
			<div className="d-grid" style={{'marginBottom': 20}}>
				<button id="updateProfile" className="btn btn-primary py-3" onClick={onSubmitCard}>
					Update Now
				</button>
			</div>
		</>
		
	)
 
};

const Subscription = ({pcount, onPostCard, onPostGetCode, update}) => {
	
	const navigate = useNavigate();
	const credentials = useSelector(selectCredentials);
	const bankaccount = credentials.cardending;
	const [toggle, setToggle] = useState(false);
	
	return (
		<>
		<section className="content-main">
			<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
				<div className="content-header1">			
					<div><div onClick={()=> navigate("/")} className="btn btn-primary">Back to dashboard</div></div>
				</div>
				
				<Counter pcount={pcount} />
			</div>
			<div className="card shadow-sm">
				<div className="card-body">
					<div className="row">
						<div className="col-md-12 col-lg-4">
							{toggle ? (<><Link to="#" onClick={() => setToggle(false)}>Hide Form</Link><br/></>) : (<><Link to="#" onClick={() => setToggle(true)}>Show Form</Link><br/></>)}
							{toggle ? (<CardMain onPostCard={onPostCard} onPostGetCode={onPostGetCode} update={update}/>) : (<></>)}
						</div>						
						<div className="col-md-12 col-lg-8">
							{bankaccount && (<span>Card Number Ending: {bankaccount}</span>)}
						</div>
					</div>
				</div>
			</div>
		</section>
		</>
	)

}

export default Subscription;
