import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Counter } from "../../Components";
import { utils } from "../../Utils";
import { Divider } from 'antd';
import InputMask from "react-input-mask";

const UserMain = ({params, onPostStore}) => {
	
	const [contactName, setContactName] = useState('');
	const [primaryPhone, setPrimaryPhone] = useState('');
	const [secondaryPhone, setSecondaryPhone] = useState('');
	const [houseNo, setHouseNo] = useState('');
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [zip, setZip] = useState('');
	const [state, setState] = useState('');
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [stateOptions, setStateOptions] = useState([]);
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);	
	
	useEffect(() => {
		if(params?.country === 'US'){
			setStateOptions(utils.usstate);
			setState('');
		}
		if(params?.country === 'CA'){
			setStateOptions(utils.canadaProvince);
			setState('');
		}
  }, [params?.country]);
		
	
	const onSubmitAdd = (e) => 
	{
		e.preventDefault();
		let output, msg;
		document.getElementById('feedback').innerHTML = "";
				
		if (!contactName) {
			msg = 'Enter contact name';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!primaryPhone) {
			msg = 'Enter primary phone';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!secondaryPhone) {
			msg = 'Enter secondary phone';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!address) {
			msg = 'Enter street name';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!city) {
			msg = 'Enter store city';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!zip) {
			msg = 'Enter store zip';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!state) {
			msg = 'Please select state';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}
		
		output = {
			'rtype': 'addnewstore',
			'storeId': storeId,
			'email': email,
			'contactName': contactName,
			'storeState': state,
			'storeCity': city,
			'storeZip': zip,
			'storeAddress': houseNo+' '+address,
			'primaryPhone': primaryPhone,
			'secondaryPhone': secondaryPhone,
		};
		
		
		onPostStore(output);	
	}
	
	return (
		<>
			<div id="toggleDisplay">
				<div className="mb-4">
					<label htmlFor="contactName" className="form-label">
						<b>Contact Name</b> *
					</label>
					<input className="form-control py-3 tags-editor" type="text" name="contactName" id="contactName" placeholder="Contact Name" title="Contact Name" value={contactName} onChange={(e) => setContactName(e.target.value)} />
				</div>
				
				<div className="mb-4">
					<label htmlFor="primaryPhone" className="form-label">
						<b>Primary Phone</b> *
					</label>
					<InputMask
					mask={`(999) 999-9999`}
					id="primaryPhone"
					name="primaryPhone"
					placeholder={`(999) 999-9999`}
					className="form-control py-3 full tags-editor"
					autoComplete="off"
					value={primaryPhone}
					onChange={(e) => setPrimaryPhone(e.target.value)}
					/>
				</div>
				
				<div className="mb-4">
					<label htmlFor="secondaryPhone" className="form-label">
						<b>Secondary Phone</b> [This will be made public] *
					</label>
					<InputMask
					mask={`(999) 999-9999`}
					id="secondaryPhone"
					name="secondaryPhone"
					placeholder={`(999) 999-9999`}
					className="form-control py-3 full tags-editor"
					autoComplete="off"
					value={secondaryPhone}
					onChange={(e) => setSecondaryPhone(e.target.value)}
					/>
				</div>
				
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullthird">				
						<label htmlFor="city" className="form-label">
						<b>House No</b>
						</label><input className="form-control py-3 full tags-editor" type="text" name="houseNo" id="houseNo" placeholder="No" title="House No" value={houseNo} onChange={(e) => setHouseNo(e.target.value)} />
					</div>				
					<div className="mb-4 fulltwothird-right">
						<label htmlFor="address" className="form-label">
						<b>Street Name</b> *
						</label>
						<input className="form-control py-3 tags-editor" type="text" name="address" id="address" placeholder="Store Address" title="Store Address" value={address} onChange={(e) => setAddress(e.target.value)} />
					</div>
				</div>
				
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="city" className="form-label">
						<b>Store City</b> *
						</label><input className="form-control py-3 full tags-editor" type="text" name="city" id="city" placeholder="Store City" title="Store City" value={city} onChange={(e) => setCity(e.target.value)} />
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="zip" className="form-label">
						<b>Store Zip/Postal Code</b> *
						</label><input className="form-control py-3 full tags-editor" type="text" name="zip" id="zip" placeholder="Store Zip" title="Store Zip" value={zip} maxLength={7} onChange={(e) => setZip(e.target.value)} />
					</div>
				</div>	
				
				<div className="mb-4">
					<label htmlFor="state" className="form-label">
						<b>State/Province</b> *
					</label>
					
					<select
						className="form-control py-3 tags-editor"
						name="state"
						title="State"
						id="state"
						value={state}
						onChange={(e) => setState(e.target.value)}
					>
						<option value="">Select State/Province</option>
						{stateOptions.map((stateObj) => (
							<option value={stateObj.value} key={stateObj.id}>
								{stateObj.label}
							</option>
						))}
					</select>
				</div>			
				
				<Divider />
				
				<div className="incsizespan center mb-2" id="feedback">
					
				</div>
				<div className="d-grid" style={{'marginBottom': 20}}>
					<button id="addStore" className="btn btn-primary py-3" onClick={onSubmitAdd}>
						Submit
					</button>
				</div>
			</div>
		</>
	)
 
};

const AddStore = ({params, pcount, onPostStore}) => {

	const navigate = useNavigate();
	
	return (
		<>
		<section className="content-main">
			<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
				<div className="content-header1">			
					<div><div onClick={()=> navigate("/manage-store", {replace: true })} className="btn btn-primary">Back to manage store</div></div>
				</div>
				
				<Counter pcount={pcount} />
			</div>
			<div className="card shadow-sm">
				<div className="card-body">
					<div className="row">
						<div className="col-md-12 col-lg-4">
							<UserMain params={params} onPostStore={onPostStore} />
						</div>						
						<div className="col-md-12 col-lg-8">
										
						</div>
					</div>
				</div>
			</div>
		</section>
		{/*<AddFooter />*/}
		</>
	)

}

export default AddStore;
