import React from "react";
import { useNavigate } from "react-router-dom";


const RouteNav = () => {
	const navigate = useNavigate();
	
	return (
	<>
    <div className="row">      
      <div className="col-lg-4">
        <div className="card card-body mb-4 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-primary">
              <i className="text-primary fas"></i>
            </span>
            <div className="text">
              <h5 className="mb-1">Subscription Settings</h5> <span>{`Manage subscription.`}</span> <span style={{color: '#3888fd', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=> navigate('/billing', { replace: true })}>Edit</span>
            </div>
          </article>
        </div>
      </div>
	  <div className="col-lg-4">
        <div className="card card-body mb-4 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-success">
              <i className="text-success fas"></i>
            </span>
            <div className="text">
				<h5 className="mb-1">Store Settings</h5> <span>{`Manage store settings.`}</span> <span style={{color: '#3888fd', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=> navigate('/manage-store', { replace: true })}>Update</span>
            </div>
          </article>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card card-body mb-4 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-warning">
              <i className="text-warning fas"></i>
            </span>
            <div className="text">
              <h5 className="mb-1">Manage Category</h5>
              <span>{`Add store menu item category.`}</span> <span style={{color: '#3888fd', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=> navigate('/create-category', { replace: true })}>Update</span>
            </div>
          </article>
        </div>
      </div>
    </div>
	
	<div className="row" style={{marginBottom: 20}}>	
      <div className="col-lg-6" style={{marginBottom: 20}}>
        <div className="card card-body mb-6 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-primary">
              <i className="text-primary fas"></i>
            </span>
            <div className="text">
              <h5 className="mb-1">Change Password</h5>
              <span>{`Change your pasword here.`}</span> <span style={{color: '#3888fd', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=> navigate('/change-password', { replace: true })}>Update</span>
            </div>
          </article>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card card-body mb-6 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-success">
              <i className="text-success fas"></i>
            </span>
            <div className="text">
				<h5 className="mb-1">Support</h5> <span>{`Feel free to reach out for help.`}</span> <span style={{color: '#3888fd', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=> navigate('/support', { replace: true })}>Continue</span>
            </div>
          </article>
        </div>
      </div>
    </div>
	</>
	);
};

export default RouteNav;
