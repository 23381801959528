import React, { useState, useEffect } from "react";
import { selectCredentials } from "../../Includes/NavSlice";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "../../Utils";
import errandlocal50 from "../../Images/errandlocal50.svg";
import { Link, useNavigate } from "react-router-dom";


const ForgotPasswordScreen = () => 
{
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const os = '';
	const model = '';
	const credentials = useSelector(selectCredentials);
	const [email, setEmail] = useState('');
	const [click, setClick] = useState(false);
	const [npass, setNpass] = useState(false);	
	const [rpass, setRpass] = useState(false);
	const [emailcode, setEmailCode] = useState('');
	const [pass, setPassword] = useState('');
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Forgot Password | Restaurant | ErrandLocal";
	}, []);
  
	const forgotEmail = () => 
	{
		let msg, output;
		if(!email){
			msg = 'Email is required';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}
		output = {
			'rtype': 'forgot',
			'email': email,
			'os': os,
			'model': model,
			'localtime': utils.getTime(),
		}
		onPostForgotEmail(output);	
	}
	
	const submitForgot = async () => 
	{
		var msg, output;
		
		if(!email){
			msg = 'Email is required';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}
		else if(!pass)
		{
			msg = 'Password is required';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}
		else if(npass && !emailcode)
		{
			msg = 'Email code is required';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}
		output = {
			'rtype': 'setpass',
			'email': email,
			'emailcode': emailcode,
			'pass': pass,
			'os': os,
			'model': model,
			'localtime': utils.getTime(),
		}
		onPostForgot(output);
	}
	
	const onPostForgotEmail = async (param) =>
	{	
		try{
			
			setClick(true);
			document.getElementById("feedback").innerHTML = '';
			document.getElementById("feedback").className = '';
			const header = await utils.genHeader();
			const merchantURL = await utils.MERCHANT_URL;
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);						
			const data = await res.json();
			setClick(false);		
			
			if(data.msg === 'Success'){			
				if(data.screen === 'requestPassword'){
					setRpass(true);
					setNpass(false);
				}else if(data.screen === 'newPassword'){
					setNpass(true);
					setRpass(false);
					setEmail(param?.email);
				}				
			}else{
				document.getElementById("feedback").innerHTML = data.msg;
				document.getElementById("feedback").className = 'incsizespan_error center mb-2';
			}
		}catch(e){console.log(e.message); setClick(false);}
	}
	
	const onPostForgot = async (param) =>
	{	
		try{
			setClick(true);
			document.getElementById("feedback").innerHTML = '';
			document.getElementById("feedback").className = '';
			const header = await utils.genHeader();
			const merchantURL = await utils.MERCHANT_URL;
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
			const data = await res.json();
			setClick(false);			
			
			if(data.msg === 'Success'){
				if(rpass){
					const param = await utils.fetchStoreUser(data.email);
					navigate('/');
				}else if(npass){
					const param = await utils.fetchStoreUser(data.email);
					alert('Please login! Password have been updated')
					navigate('/login');
				}
			}else{
				document.getElementById("feedback").innerHTML = data.msg;
				document.getElementById("feedback").className = 'incsizespan_error center mb-2';
			}
		}catch(e){console.log(e.message); setClick(false);}
	}
	
	
	return (
    <>
      <div
        className="card shadow mx-auto"
        style={{ maxWidth: "380px", marginTop: "100px" }}
      >
        <div className="card-body">          
		  
		  <h6 className="card-title mb-4 text-center">
				<img src={errandlocal50} className="w-full object-cover" alt="logo" /><br/>
				{npass ? `Set Password` : `Forgot Password`}
		  </h6>
          <div>
            {!npass && (<div className="mb-3">
				<input className="form-control py-3 tags-editor" type="email" name="email" id="email" placeholder="Email" title="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>)}
            {npass && (<div className="mb-3">
				<input className="form-control py-3 tags-editor" type="password" name="password" id="password" placeholder="Password" title="Password" value={pass} onChange={(e) => setPassword(e.target.value)} />
			</div>)}
            {rpass && (<div className="mb-3">
				<input className="form-control py-3 tags-editor" type="password" name="password" id="password" placeholder="Password" title="Password" value={pass} onChange={(e) => setPassword(e.target.value)} />
			</div>)}
            {npass && (<div className="mb-3">
				<input className="form-control py-3 tags-editor" type="text" name="emailcode" id="emailcode" placeholder="Email Code" title="Email Code" value={emailcode} onChange={(e) => setEmailCode(e.target.value)} />
			</div>)}
			
			<div className="incsizespan center mb-2" id="feedback">
				
			</div>

            <div className="mb-4 d-grid">              
			  {!npass && !rpass && (<button onClick={()=>click ? alert('Wow') : forgotEmail()} type="button" className="btn btn-primary w-100 py-3">
                Continue
              </button>)}
			  {npass && (<button onClick={()=>click ? '' : submitForgot()} type="button" className="btn btn-primary w-100 py-3">
                Submit
              </button>)}
			  {rpass && (<button onClick={()=>click ? '' : submitForgot()} type="button" className="btn btn-primary w-100 py-3">
                Submit
              </button>)}
			  
            </div>
			<div className="mb-3">
				<Link to="/login">Login Here</Link>
			</div>
          </div>
        </div>
      </div>
    </>
	);
};

export default ForgotPasswordScreen;
