import React from "react";
import { utils } from "../../Utils";
import { Divider } from 'antd';

const OrderDetailInfo = ({orderdata}) => {
	
	return (
    <div className="row mb-5 order-info-wrap">
      <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-user"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Customer</h6>
            <p className="mb-1">
              <b><span className="text-success">{orderdata[0]?.firstname} {orderdata[0]?.lastname[0]}</span></b> <br />{`${utils.convertToUSPhoneFormat(orderdata[0]?.mainphone)}`}
            </p>
          </div>
        </article>
		<Divider />
      </div>
      <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-truck-moving"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Order Info</h6>
            {orderdata[0]?.deliveryType === 'Delivery' ? (<p className="mb-1">
			  <b>Picker:</b>			
			  <b> <span className="text-success">{orderdata[0]?.courierfirstname} {orderdata[0]?.courierlastname[0]}</span></b> [{orderdata[0]?.courierphone}]			
			  <br /><b>Expected Ready Time:</b> {utils.dateToString(orderdata[0]?.expectedPickupTimestamp - (60*5), 'dateTime', orderdata[0]?.timeZone)}
			  <br /><b>Expected Pickup Time:</b> {utils.dateToString(orderdata[0]?.expectedPickupTimestamp, 'dateTime', orderdata[0]?.timeZone)}
            </p>) : (<p className="mb-1">
			  <b>Picker:</b>			
			  <b> <span className="text-success">{orderdata[0]?.firstname} {orderdata[0]?.lastname[0]}</span></b> [{`${utils.convertToUSPhoneFormat(orderdata[0]?.deliveryphone)}`}]			
			  <br /><b>Expected Ready Time:</b> {utils.dateToString(orderdata[0]?.expectedPickupTimestamp - (60*5), 'dateTime', orderdata[0]?.timeZone)}
            </p>)}
          </div>
        </article>
		<Divider />
      </div>
      <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-map-marker-alt"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Deliver To</h6>
            {orderdata[0]?.deliveryType === 'Delivery' ? (<p className="mb-1">
              <b>Address:</b> <br />{orderdata[0]?.storeaddress}
              <br />{orderdata[0]?.storecity}, {orderdata[0]?.storestate}, {orderdata[0]?.storezip}<br/ >{`${utils.convertToUSPhoneFormat(orderdata[0]?.deliveryphone)}`}
            </p>) : (<p className="mb-1"><b>Address:</b> <br />Pick up at the store.</p>)}
          </div>
        </article>
		<Divider />
      </div>
    </div>
	);
};

export default OrderDetailInfo;
