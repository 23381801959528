import React from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import { useSelector, useDispatch } from "react-redux";
import { selectOrders, setOrders } from "../../Includes/NavSlice";
import { utils } from "../../Utils";

const renderOrderType = (stats) => {
    const statusToColor = {
		"Pickup": "alert-danger declined",
		"Delivery": "alert-warning warned",
    };
	const statusToVal = {
		"Pickup": "PICK UP",
		"Delivery": "DELIVERY",
    };
	const el = statusToColor[stats];

	return <span className={`${el} orderstatus rounded-pill`}>{statusToVal[stats]}</span>;
};

const renderOrderAmount = (stats) => {   

	return <span style={{color: "black"}}><b>{stats}</b></span>;
};

const LatestOrder = ({initOrders}) => {
	
	let dispatch = useDispatch();
	initOrders = typeof initOrders === "object" ? initOrders : JSON.parse(initOrders);
	let orders = initOrders;
	let latestorders = [];
	if(orders[0]?.storeId){
		orders = orders.map((order, i) => {
			let index = i+1;		
			if(index < 6){
				if(latestorders.indexOf({ ...order, index }) === -1){
					latestorders.push({ ...order, index });
				}			
			}
			return { ...order, index };		
		});
	}else if(initOrders[0]?.storeId){
		dispatch(setOrders(initOrders));
		orders = initOrders;
		orders = orders.map((order, i) => {
			let index = i+1;		
			if(index < 6){
				if(latestorders.indexOf({ ...order, index }) === -1){
					latestorders.push({ ...order, index });
				}			
			}
			return { ...order, index };		
		});
	}
	
	
	createTheme('solarized', {
	  text: {
		primary: '#4fa607',
		secondary: '',
	  },
	  background: {
		default: '',
	  },
	  context: {
		background: '#cb4b16',
		text: '#cdd6d9',
	  },
	  divider: {
		default: '#4fa607',
	  },
	  action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	  },
	}, 'light');
	
	const columns = [		
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Verdana'}}>S/N</div>,
			selector: row => row?.index,
			sortable: true,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
			width: '70px',
		},	
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Verdana'}}>TIME</div>,
			selector: row => <span style={{color: '#001529', opacity: 0.7, fontWeight: 600, fontSize: 15}}>{utils.dateToString(row.createdTimestamp)}</span>,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '210px',
		},
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Verdana'}}>ORDER ID</div>,
			selector: row => row?.orderId,
			sortable: false,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
			width: '150px',
		},
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Verdana'}}>ORDER TYPE</div>,
			selector: row => renderOrderType(row.deliveryType),
			sortable: true,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
		},	
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Verdana'}}>AMOUNT</div>,
			selector: row => renderOrderAmount('$'+parseFloat((parseFloat(row.subtotal) / 100) + (parseFloat(row.salestax) / 100)).toFixed(2)),
			sortable: true,
			wrap: true,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
			width: '150px',
		},
	];
	
	return (
	<div className="card-body">
		<h5 className="card-title">Latest orders</h5>
        <div className="table-responsive">
				<DataTable
					columns={columns}
					data={latestorders}
					striped
					responsive
					theme="light"
					highlightOnHover
				/>
		</div>
	</div>   
	);
};

export default LatestOrder;
