import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { utils } from "../../Utils";
import { Counter } from "../../Components";
import { v4 as uuidv4 } from "uuid";
import Item from "./Item";


let maindata = [];

const ItemList = ({pcount, onPostDelete, updatedelete, onPostAction}) => {	
	
	const navigate= useNavigate();
	const [storeId, setStoreId] = useState('');
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	
	useEffect(() => 
	{	
		const storeId = localStorage.getItem("userstoreId");
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	const [products, setProducts] = useState([]);
	const [mainproducts, setMainProducts] = useState([]);
	const [search, setSearch] = useState("");
	const [lastid, setLastId] = useState("");
	
	useEffect(() => {
	
	  if (storeId && storeId !== 'undefined') {		  
		const interval = setTimeout(async() => {			
		  const data = await fetchProducts(storeId);			
			if(data?.length > 0){
				const nlastId = data[((data?.length) - 1)]?.itemId;
				setLastId(nlastId);
				setProducts(data);
				setMainProducts(data);
				maindata = data;
			}
			
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [storeId]);
	
	
	const fetchProducts = async (storeId) => 
	{
		setLoading(true);
		const productURL = await utils.MERCHANT_CALL+'?request=item&storeId='+storeId;
		const header = await utils.genHeader();
		const res = await utils.fetchURL(productURL, 'GET', '', header);
		const data = await res.json();
		setLoading(false);
		return data;
	}
	
	const fetchProducts2 = async (storeId, from, limit) => 
	{
		setIsLoading(true);
		const productURL = await utils.MERCHANT_CALL+'?request=item&storeId='+storeId+'&from='+from+'&limit='+limit;
		const header = await utils.genHeader();
		const res = await utils.fetchURL(productURL, 'GET', '', header);
		const data = await res.json();
		setIsLoading(false);
		return data;
	}
	
	useEffect(() => 
	{	
		if(updatedelete){
			if (storeId && storeId !== 'undefined') {
				const interval = setTimeout(async() => {				
				  const data = await fetchProducts(storeId);					
					if(data.length > 0){
						const nlastId = data[((data?.length) - 1)]?.itemId;
						setLastId(nlastId);
						setProducts(data);
						setMainProducts(data);
						maindata = data;
					}
				}, 100);
				return () => clearTimeout(interval); 
			}
		}
		// eslint-disable-next-line
	}, [updatedelete]);
	
	
	
	useEffect(() => 
	{		
		const getProduct2 = async () => 
		{
			const paramLen = mainproducts?.length ?? 0;			
			if(lastid && paramLen > 0	&& storeId && storeId !== 'undefined')
			{
				const lastId = mainproducts[(paramLen-1)].itemId;
				if(lastId && lastid === lastId)
				{					
					let params = await fetchProducts2(storeId, lastId, 10);
					if(params?.length > 0){
						let newparams = [...mainproducts, ...params];		
						const nlastId = params[((params?.length) - 1)]?.itemId;
						setLastId(nlastId);
						setProducts(newparams);
						setMainProducts(newparams);
					}else{
						setLastId("");
					}
				}
			}
		}
		getProduct2(); 
		
	},[mainproducts]);	
	
	function onSearch(search)
	{
		setProducts(mainproducts);
		search = search.toLowerCase();		
        //filter restaurant	
		if(search !== ''){	
			let nList = mainproducts.filter(a => a.name.toLowerCase().indexOf(search) > -1);
			setProducts(nList);
		}
  }
	
	return (
    <section className="content-maino">
	  <div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
		<div className="content-header1">			
			<div><div onClick={()=> navigate("/")} className="btn btn-primary">Back to dashboard</div></div>
        </div>
		
		<Counter pcount={pcount} />
	  </div>
	
      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                placeholder="Filter By Product Name..."
                className="form-control p-2 tags-editor"
				onKeyUp={()=> onSearch(search)}
				value={search} 
				onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              
            </div>
          </div>
        </header>

        <div className="card-body">
          <div className="row">
            {/* Products */}
            {!loading && products.length > 0 && products?.map((product) => (
              <Item product={product} key={uuidv4()} onPostDelete={onPostDelete} onPostAction={onPostAction} />
            ))}
						{!loading && products.length === 0 && (<div style={{justifyContent: 'center', padding: '50px 20px', alignItems: 'center', display: 'flex', fontSize: 20, fontWeight: 900, opacity: 0.5}}>NO ITEM WAS FOUND</div>)}
						{loading && products.length === 0 && (<div style={{justifyContent: 'center', padding: '50px 20px', alignItems: 'center', display: 'flex', fontSize: 20, fontWeight: 900, opacity: 0.5}}>LOADING...</div>)}
						{isLoading && mainproducts.length > 0 && (<div style={{ padding: '50px 20px', alignItems: 'center', display: 'flex', fontSize: 15, fontWeight: 600, opacity: 0.5}}>LOADING MORE...</div>)}
          </div>

        </div>
      </div>
    </section>
	);
};

export default ItemList;
