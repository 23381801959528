import React from "react";
import CreateCategory from "./CreateCategory";
import CategoriesTable from "./CategoriesTable";
import { useNavigate } from "react-router-dom";
import { Counter } from "../../Components";

const Category = ({pcount, onPostCategory, onPostDeleteCategory, update, setUpdate}) =>
{	

	const navigate = useNavigate();
	
	return (
		<section className="content-main">
		  <div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
			<div className="content-header1">			
				<div><div onClick={()=> navigate("/")} className="btn btn-primary">Back to dashboard</div></div>
			</div>
			
			<Counter pcount={pcount} />
		  </div>
				
		  <div className="card shadow-sm">
			<div className="card-body">
			  <div className="row">
				{/* Create category */}
				<CreateCategory onPostCategory={onPostCategory} />
				{/* Categories table */}
				<CategoriesTable onPostDeleteCategory={onPostDeleteCategory} update={update} setUpdate={setUpdate}/>
			  </div>
			</div>
		  </div>
		</section>
	);
};

export default Category;
