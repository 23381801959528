import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { selectCredentials } from "../../Includes/NavSlice";


const CreateCategory = ({onPostCategory}) => 
{
	const credentials = useSelector(selectCredentials); 
	const [categoryName, setCategoryName] = useState('');
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	
	const onSubmitAdd = (e) => 
	{
		e.preventDefault();
		var categoryName, msg, output;
		
		categoryName = document.getElementById('categoryName').value;
		
		if (!categoryName) {
			msg = 'Enter item category name';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center';
			return false;
		}	
		
		output = {
			'rtype': 'addcategory',
			'email': email,
			'storeId': storeId,
			'category': categoryName
		}
		
		onPostCategory(output);	
	}
	
	
	return (
		<div className="col-md-12 col-lg-4">			
			<div className="mb-4">
				<label htmlFor="categoryName" className="form-label">
					<b>Item Category Name</b>
				</label>
				<input className="form-control py-3 tags-editor" type="text" name="categoryName" id="categoryName" placeholder="Category Name" title="Category Name" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
			</div>
			<div className="incsizespan center" id="feedback">
				
			</div>
			<div className="d-grid">
				<button className="btn btn-primary py-3" onClick={onSubmitAdd}>Create category</button>
			</div>
		</div>
	);
};

export default CreateCategory;
