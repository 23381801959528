import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

const NotFoundScreen = () => {	
	const [state, setState] = useState(false);
	
  useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Resource Not Found | Restaurant | ErrandLocal";
  }, []);
	
	useEffect(() => {
		setTimeout(()=>{
			setState(true);
		}, 8000);
  }, []);
  
	if(!state){
		return null;
	}
  return (
    <div className="not-found-container">
      <h1 className="not-found-heading">404</h1>
      <p className="not-found-message">{`Oops! The page you're looking for doesn't exist.`}</p>
			<Link to="/" className="not-found-link">Go to Home Page</Link>
    </div>
  );
};

export default NotFoundScreen;