import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import {
  CloseOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
  SaveOutlined,
  CopyOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Upload,
  Button,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Divider,
  Select,
  Radio,
  Form,
  Tree,
  notification,
  Space,
  Tooltip,
  message
} from "antd";
import { AddFormItem } from "./AddFormItem";
import "./AddTable.css";
import { v4 as uuidv4 } from "uuid";

const { Column } = Table;
const { Option } = Select;
let addonOptions = [
	{value: 'default', label: 'Add a side'},
	{value: 'Cheese Type', label: 'Cheese Type'},
	{value: 'Dressing Selection', label: 'Dressing Selection'},
	{value: 'Egg Option', label: 'Egg Option'},
	{value: 'Sandwich Extras', label: 'Sandwich Extras'},
	{value: 'Sandwich Options', label: 'Sandwich Options'},
	{value: 'Toppings', label: 'Toppings'},
];
let Colors = {
  parentField: 'none',
  childField: '#1677ff',
  grandChildField: '#730073',
  greatGrandChildField: '#0ecb81',
};

export const AddTable = (props) => {
	
  const { itemData, form, add, remove, data, categories, size } = props;
  const [editingIndex, setEditingIndex] = useState(undefined);
  const [isNewUser, setNewUser] = useState(false);
  const [inputError, setInputError] = useState("");
  const [buttonAdd, setButtonAdd] = useState("default");
  const [addon, setAddon] = useState("");
  const [fields, setFields] = useState([]);
  const [running, setRunning] = useState(false);
  const [count, setCount] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [editIndex, setEditIndex] = useState("");
  const [rowExpanded, setRowExpanded] = useState("Close");
  const [addonEntries, setAddonEntries] = useState([]);
  const [copyIndex, setCopyIndex] = useState("");  
  const [copyFieldIndex, setCopyFieldIndex] = useState("");
  const [copied, setCopied] = useState(false);
  const [formReset, setFormReset] = useState(false);
  const [hoverTitle, setHoverTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [blobUrl, setBlobUrl] = useState(null);
  const [itemImage, setItemImage] = useState("");
  
  const hoverRef = useRef();
  
  const [items, setItems] = useState(categories);
  const [name, setName] = useState('');
  const inputRef = useRef(null);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
	if(name === ''){
		alert('Please enter a category name');
		return false;
	}
    setItems([...items, { value: name, label: name }]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
 
  useEffect(() => {
    if(formReset){
		setButtonAdd("default");
		setAddon("");
		setFields([]);
		setCount(0);
		setRunning(true);
		setMin(0);
		setMax(0);
		setCopyIndex("");
		setEditIndex("");
		if(document.getElementById('addonform')){
			setAddonEntries(addonEntries);
			document.getElementById('addonform').style.display = 'none';
		}
	}
  }, [formReset]);
  
  useEffect(() => {
    let interval = null;

    if (running) {
      interval = setInterval(() => {		
        onSaveOptions();
		setCount((prevCount) => prevCount + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [running]);
  
  const openFileModal = (e) =>
  {
	if(document.getElementById("image_file"))
	{	
		setItemImage(itemImage);
		document.getElementById('image_file').click();
		document.getElementById("image_file").addEventListener("change", imageChange);		
	}
  }
  
  const imageChange = (e) =>
  {
	if(document.getElementById("image_file"))
	{
		var name = document.getElementById("image_file");
		var file = name?.files[0];
		var filen = name?.files?.item(0)?.name;			
		//var files = name?.files?.item(0)?.size;
		//var filet = name?.files?.item(0)?.type;			
		if(filen)
		{				
			setItemImage(filen);
		}			
	}
  }

  
  const handleTooltip = (index) => {
	setCopied(true);
    message.success("Copied");
    setTimeout(() => {
	  setCopied(false);
    }, 5000);
  };
  
  const handleExpand = (expanded, record) => {
	if (expanded) {
      setRowExpanded('Open');
    } else {
      setRowExpanded('Close');
    }
    setRunning(!running);
  };

  const addAddon = (inputId) => {
	setInputError('');
	document.getElementById('addonform').style.display = 'none';
	let inputVal = document.getElementById(inputId).value;
	if(!inputVal){
		setInputError('Addon is Required');
		setFields([]);
		return false;
	}
	let addonArr = [];	
	for(var i=0; i<addonEntries.length; i++){
		addonArr.push(addonEntries[i].addon.trim().toLowerCase());
	}
	
	if(addonArr.includes(inputVal.trim().toLowerCase())){
		notification.info({
		  message: "Error",
		  description: inputVal+" is added already. Edit instead."
		});
		return false
	}
	
	setAddon(inputVal);
	onSaveOptions();
  };
  
  const addField = () => {
	setFormReset(false);
	document.getElementById('addonform').style.display = 'block';
    setFields([
      ...fields,
      {
        id: uuidv4(),
        option: "",
        amount: 0,
        subFields: [],
      },
    ]);
	onSaveOptions();
  };
  
  const minusField = (index) => { 
	  const newFields = [...fields];
	  newFields.splice(index, 1);
	  setFields(newFields);
	  onSaveOptions();
	  if(fields.length === 1){
		document.getElementById('addonform').style.display = 'none';
	  }
  };
  
  const copyField = async (index) => {	  
	  setCopyFieldIndex(index);
	  message.success('Data Copied');
  };
  
  const attachField = async (index) => {
	let value = fields[copyFieldIndex];		 
	let listObject = {};
	//value.id = uuidv4();
	const id = uuidv4();
	const option = value.option;
	const amount = value.amount;
	
	listObject.id = id;
	listObject.option = option;
	listObject.amount = amount;
	listObject.subFields = [];

	// loop through the subFields array and get the index
	value.subFields.forEach(async(subfield, subfieldIndex) => {
	  
	  let listObject2 = {};
	  //subfield.id = uuidv4();
	  const subid = uuidv4();
	  const suboption = subfield.option;
	  const subamount = subfield.amount;
	  const subdescription = subfield.description;
	  const submin = subfield.min;
	  const submax = subfield.max;
	  
	  listObject2.id = subid;
	  listObject2.option = suboption;
	  listObject2.amount = subamount;
	  listObject2.description = subdescription;
	  listObject2.min = submin;
	  listObject2.max = submax;
	  listObject2.subSubFields = [];
	  listObject.subFields.push(listObject2);
	  
	  // loop through the subSubFields array and get the index
	  subfield.subSubFields.forEach(async(subsubfield, subsubfieldIndex) => {
		  
		let listObject3 = {};
		//subsubfield.id = uuidv4();
		const subsubid = uuidv4();
		const subsuboption = subsubfield.option;
		const subsubamount = subsubfield.amount;			
		const subsubdescription = subsubfield.description;			
		const subsubmin = subsubfield.min;			
		const subsubmax = subsubfield.max;			
		
		listObject3.id = subsubid;
		listObject3.option = subsuboption;
		listObject3.amount = subsubamount;
		listObject3.description = subsubdescription;
		listObject3.min = subsubmin;
		listObject3.max = subsubmax;
		listObject3.subSubSubFields = [];
		listObject2.subSubFields.push(listObject3);
		
		// loop through the subSubSubFields array and get the index
		subsubfield.subSubSubFields.forEach(async(subsubsubfield, subsubsubfieldIndex) => {			  
			let listObject4 = {};
			//subsubsubfield.id = uuidv4();
			const subsubsubid = uuidv4();
			const subsubsuboption = subsubsubfield.option;
			const subsubsubamount = subsubsubfield.amount;			
			const subsubsubdescription = subsubsubfield.description;			
			const subsubsubmin = subsubsubfield.min;			
			const subsubsubmax = subsubsubfield.max;			
			
			listObject4.id = subsubsubid;
			listObject4.option = subsubsuboption;
			listObject4.amount = subsubsubamount;
			listObject4.description = subsubsubdescription;
			listObject4.min = subsubsubmin;
			listObject4.max = subsubsubmax;
			listObject3.subSubSubFields.push(listObject4);			
		});		
	  });
	});
	
	let fieldsLen = fields.length;
	let infoArr = [];
	
	for(var i=0; i<fieldsLen; i++){
		if(i === index){
			infoArr.push(listObject);
		}else{
			infoArr.push(fields[i]);
		}
	}	  
	setFields(infoArr);
  };

  const handleOptionChange = (index, event) => {
    const newFields = [...fields];
    newFields[index].option = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleAmountChange = (index, event) => {
    const newFields = [...fields];
    newFields[index].amount = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };
  
  const handleDescriptionChange = (index, event) => {
    const newFields = [...fields];
    newFields[index].description = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };
  
  const handleMinChange = (index, event) => {
    const newFields = [...fields];
    newFields[index].min = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };
  
  const handleMaxChange = (index, event) => {
    const newFields = [...fields];
    newFields[index].max = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubOptionChange = (parentIndex, index, event) => {
    const newFields = [...fields];
    newFields[parentIndex].subFields[index].option = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubAmountChange = (parentIndex, index, event) => {
    const newFields = [...fields];
    newFields[parentIndex].subFields[index].amount = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };
  
  const handleSubDescriptionChange = (parentIndex, index, event) => {
    const newFields = [...fields];
    newFields[parentIndex].subFields[index].description = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };
  
  const handleSubMinChange = (parentIndex, index, event) => {
    const newFields = [...fields];
    newFields[parentIndex].subFields[index].min = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };
  
  const handleSubMaxChange = (parentIndex, index, event) => {
    const newFields = [...fields];
    newFields[parentIndex].subFields[index].max = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubOptionChange = (
    parentParentIndex,
    parentIndex,
    index,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[
      index
    ].option = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubAmountChange = (
    parentParentIndex,
    parentIndex,
    index,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[
      index
    ].amount = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubDescriptionChange = (
    parentParentIndex,
    parentIndex,
    index,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[
      index
    ].description = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubMinChange = (
    parentParentIndex,
    parentIndex,
    index,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[
      index
    ].min = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubMaxChange = (
    parentParentIndex,
    parentIndex,
    index,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[
      index
    ].max = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubSubOptionChange = (
    parentParentIndex,
    parentIndex,
    index,
    lastindex,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[index].subSubSubFields[
      lastindex
    ].option = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubSubAmountChange = (
    parentParentIndex,
    parentIndex,
    index,
	lastindex,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[index].subSubSubFields[
      lastindex
    ].amount = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubSubDescriptionChange = (
    parentParentIndex,
    parentIndex,
    index,
    lastindex,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[index].subSubSubFields[
      lastindex
    ].description = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubSubMinChange = (
    parentParentIndex,
    parentIndex,
    index,
    lastindex,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[index].subSubSubFields[
      lastindex
    ].min = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const handleSubSubSubMaxChange = (
    parentParentIndex,
    parentIndex,
    index,
    lastindex,
    event
  ) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[index].subSubSubFields[
      lastindex
    ].max = event.target.value;
    setFields(newFields);
	onSaveOptions();
  };

  const addSubField = (parentIndex) => {
    const newFields = [...fields];
    newFields[parentIndex].subFields = [
      ...newFields[parentIndex].subFields,
      {
        id: uuidv4(),
        option: "",
		amount: 0,
        description: "",
        min: 0,
        max: 0,
        subSubFields: [],
      },
    ];
    setFields(newFields);
	onSaveOptions();
  };
  
  const minusSubField = (parentParentIndex, parentIndex) => {    
	const newFields = [...fields];
    newFields[parentParentIndex].subFields.splice(parentIndex, 1);
    setFields(newFields);
	onSaveOptions();
  };
  
  const addSubSubField = (parentParentIndex, parentIndex) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields = [
      ...newFields[parentParentIndex].subFields[parentIndex].subSubFields,
      {
		id: uuidv4(),
        option: "",
		amount: 0,
        description: "",
        min: 0,
        max: 0,
		subSubSubFields: [],
      },
    ];
    setFields(newFields);
	onSaveOptions();
  };
  
  const minusSubSubField = (parentParentIndex, parentIndex, subSubIndex) => {
	  const newFields = [...fields];
	  newFields[parentParentIndex].subFields[parentIndex].subSubFields.splice(subSubIndex, 1);
	  setFields(newFields);
	  onSaveOptions();
  };
  
  const addSubSubSubField = (parentParentIndex, parentIndex, childIndex) => {
    const newFields = [...fields];
    newFields[parentParentIndex].subFields[parentIndex].subSubFields[childIndex].subSubSubFields = [
      ...newFields[parentParentIndex].subFields[parentIndex].subSubFields[childIndex].subSubSubFields,
      {
		id: uuidv4(),
        option: "",
		amount: 0,
        description: "",
        min: 0,
        max: 0,
      },
    ];
    setFields(newFields);
	onSaveOptions();
  };
  
  const minusSubSubSubField = (parentParentIndex, parentIndex, childIndex, subSubSubIndex) => {
	  const newFields = [...fields];
	  newFields[parentParentIndex].subFields[parentIndex].subSubFields[childIndex].subSubSubFields.splice(subSubSubIndex, 1);
	  setFields(newFields);
	  onSaveOptions();
  };
  
  const handleAddButton = async (e) => {
	let inputVal = e.target.value;
	let addonArr = [];
	
	for(var i=0; i<addonEntries.length; i++){
		addonArr.push(addonEntries[i].addon.trim().toLowerCase());		
	}
	
	if(addonArr.includes(inputVal.trim().toLowerCase()))
	{
		notification.info({
		  message: "Error",
		  description: inputVal+" is added already. Edit instead."
		});
		return false;
	}
	
	if(buttonAdd !== inputVal)
	{
		await setFormReset(true);
	}
	await setFormReset(false);
	setButtonAdd(inputVal);
	onSaveOptions();
  };
  
  const handleEdit = async (index, addonName) => {
	await setFormReset(true);
	await setEditIndex(index);
	document.getElementById('addonform').style.display = 'block';	
	let addonArr = [];
	
	for(var i=1; i<addonOptions.length; i++){
		addonArr.push(addonOptions[i].value.trim().toLowerCase());
	}
	
	if(addonArr.includes(addonName.trim().toLowerCase())){
		await setButtonAdd(addonName);
		await setAddon("");
	}else{
		await setButtonAdd("default");
		await setAddon(addonName);		
	}
	
	let list = [];
	addonEntries[index].values.forEach(async(value, valueIndex) => {
		list.push(value);
	});
	await setFields(list);
	await setMin(addonEntries[index].min);
	await setMax(addonEntries[index].max);
	await setFormReset(false);
  };
  
  const handleCopy = async (index) => {
	await setFormReset(true);
	setCopyIndex(index);	
	handleTooltip(index);
	return false;
  };
  
  const handleCopyLeave = (index) => {
	setCopied(false);
	return false;
  };
  
  const handleDelete = async (index) => {
	const newEntries = [...addonEntries];
	newEntries.splice(index, 1);
	await setAddonEntries(newEntries);
	await props.moveIt(newEntries);
	data.itemData[0].info = newEntries;	
  };
  
  const handleAttach = async (inputId) => {
	
	if(copyIndex === ''){
		notification.info({
		  message: "Error",
		  description: "No addon was copied."
		});
		return false;
	}
	setInputError('');
	document.getElementById('addonform').style.display = 'none';
	let inputVal = document.getElementById(inputId).value;
	if(!inputVal){
		setInputError('Addon is Required');
		setFields([]);
		return false;
	}
	let addonArr = [];	
	let addonLen = addonEntries.length;
	let infoArr = [];
	for(var i=0; i<addonEntries.length; i++){
		addonArr.push(addonEntries[i].addon.trim().toLowerCase());
		if(i === copyIndex){
			infoArr.push(addonEntries[i]);
		}
	}
	
	if(addonArr.includes(inputVal.trim().toLowerCase())){
		notification.info({
		  message: "Error",
		  description: inputVal+" is added already. Edit instead."
		});
		return false;
	}
	
	await setFormReset(true);
	setAddon(inputVal);
	setMin(infoArr[0].min);
	setMax(infoArr[0].max);	
	let list = [];
	
	// loop through the info array and get the index
	infoArr.forEach(async(info, infoIndex) => 
	{
	  //info.id = uuidv4();
	  const id = uuidv4();
	  const addon = info.addon;
	  const min = info.min;
	  const max = info.max;
	  
      document.getElementById(inputId).value = inputVal;	    
      
	  // loop through the values array and get the index
	  info.values.forEach(async(value, valueIndex) => {
		 
		let listObject = {};
		//value.id = uuidv4();
		const id = uuidv4();
		const option = value.option;
		const amount = value.amount;
		const description = value.description;
		const nmin = value.min;
		const nmax = value.max;
		
		listObject.id = id;
		listObject.option = option;
		listObject.amount = amount;
		listObject.subFields = [];
		
	
		// loop through the subFields array and get the index
		value.subFields.forEach(async(subfield, subfieldIndex) => {
		  
		  let listObject2 = {};
		  //subfield.id = uuidv4();
		  const subid = uuidv4();
		  const suboption = subfield.option;
		  const subamount = subfield.amount;
		  const subdescription = subfield.description;
		  const submin = subfield.min;
		  const submax = subfield.max;
		  
		  listObject2.id = subid;
		  listObject2.option = suboption;
		  listObject2.amount = subamount;
		  listObject2.description = subdescription;
		  listObject2.min = submin;
		  listObject2.max = submax;
		  listObject2.subSubFields = [];
		  listObject.subFields.push(listObject2);
		  
		  // loop through the subSubFields array and get the index
		  subfield.subSubFields.forEach(async(subsubfield, subsubfieldIndex) => {
			  
			let listObject3 = {};
			//subsubfield.id = uuidv4();
			const subsubid = uuidv4();
			const subsuboption = subsubfield.option;
			const subsubamount = subsubfield.amount;			
			const subsubdescription = subsubfield.description;			
			const subsubmin = subsubfield.min;			
			const subsubmax = subsubfield.max;			
			
		    listObject3.id = subsubid;
		    listObject3.option = subsuboption;
		    listObject3.amount = subsubamount;
		    listObject3.description = subsubdescription;
		    listObject3.min = subsubmin;
		    listObject3.max = subsubmax;
		    listObject3.subSubSubFields = [];
			listObject2.subSubFields.push(listObject3);
			
			// loop through the subSubSubFields array and get the index
			subsubfield.subSubSubFields.forEach(async(subsubsubfield, subsubsubfieldIndex) => {
				  
				let listObject4 = {};
				//subsubsubfield.id = uuidv4();
				const subsubsubid = uuidv4();
				const subsubsuboption = subsubsubfield.option;
				const subsubsubamount = subsubsubfield.amount;			
				const subsubsubdescription = subsubsubfield.description;			
				const subsubsubmin = subsubsubfield.min;			
				const subsubsubmax = subsubsubfield.max;			
				
				listObject4.id = subsubsubid;
				listObject4.option = subsubsuboption;
				listObject4.amount = subsubsubamount;
				listObject4.description = subsubsubdescription;
				listObject4.min = subsubsubmin;
				listObject4.max = subsubsubmax;
				listObject3.subSubSubFields.push(listObject4);
				
			});			
		  });
		});
		//console.log(listObject);
		list.push(listObject);
	  });
	
	});
	
	document.getElementById('addonform').style.display = 'block';
	await setFormReset(false);
	setAddon(inputVal);	
	setButtonAdd("default");
	setFields(list);
	setCount(0);
	setRunning(true);    	
	setCopyIndex('');
	onSaveOptions();	
  };  
  
  const onSaveOptions = async (view) => {
	data.itemData[0].info = addonEntries;
	
	
	if(view)
	{	
		let addonArr = [];	
		for(var i=0; i<addonEntries.length; i++){
			addonArr.push(addonEntries[i].addon.trim().toLowerCase());
		}		
		let minn, maxx, addonVal, minVal, maxVal;
		if(document.getElementById('addonMin'))
		{
			minn = document.getElementById('addonMin');
			maxx = document.getElementById('addonMax');
			
			if(buttonAdd === 'default'){			
				addonVal = addon;
			}else{
				addonVal = buttonAdd;
			}
			
			minVal = minn.value;
			maxVal = maxx.value;
			
			let newEntry = { id: uuidv4(), addon: addonVal, min: minVal, max: maxVal, values: fields };
			
			let naddonArr = [];
			if(addonArr.includes(addonVal.trim().toLowerCase())){
				let index = addonArr.indexOf(addonVal.trim().toLowerCase());			
				for(var i=0; i<addonEntries.length; i++){
					if(i === index){					
						naddonArr.push(newEntry);
					}else{
						naddonArr.push(addonEntries[i]);
					}
				}
				
				setAddonEntries(naddonArr);
			}else{
				naddonArr = [...addonEntries, newEntry];
				setAddonEntries(naddonArr);			
			}
			await props.moveIt(naddonArr);	
			setFormReset(true);
		}else{
			await props.moveIt([...addonEntries]);	
			setFormReset(true);
		}
	}
  };

  const addUser = () => {
    add();
    setEditingIndex(itemData.length);
    setNewUser(true);
  };

  const onSave = async() => {	
    form
      .validateFields()
      .then(() => {
        setNewUser(false);
        setEditingIndex(undefined);
      })
      .catch((error) => {
        console.log(error.errorFields);
      });
  };

  const onCancel = (index) => {
    if (isNewUser) {
      remove(index);
    } else {
      form.resetFields([
        ["itemData", index, "category"],
		["itemData", index, "file"],
        ["itemData", index, "name"],
        ["itemData", index, "description"],
        ["itemData", index, "price"],        
      ]);
    }
    setNewUser(false);
    setEditingIndex(undefined);
  };
    
  const ButtonList = ({addonVal, addonLabel}) => {
		let addonArr = [];
		for(var i=0; i<addonEntries.length; i++){
			addonArr.push(addonEntries[i].addon.trim().toLowerCase());
		}		
		if(addonArr.includes(addonVal.trim().toLowerCase())){
			return (<Radio.Button value={addonVal} disabled>{addonLabel}</Radio.Button>);
		}else{
			return (<Radio.Button value={addonVal} >{addonLabel}</Radio.Button>);
		}
  }
  
  const beforeUpload = (file) => {	
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
      return false;
    }
	setLoading(true);
	props.getFile(file);
    setFileList([file]);	
	setBlobUrl(URL.createObjectURL(file));
    setFileName(file.name);
	data.itemData[0].file = file.name;
	setLoading(false);
    return false;
  };
  
  const removeFile = () => {    
	setLoading(false);
	props.getFile(null);
    setFileList([]);	
	setBlobUrl(null);
    setFileName(null);
	data.itemData[0].file = 'File.jpg';
    return false;
  };

  const handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
    }
  };
  
  return (
    <Table
      dataSource={itemData}
      pagination={false}      
      onCell={(record, index) => console.log('Open')}
	  onExpand={handleExpand}
      expandable={{
        expandedRowRender: (record) => 
		(		  
          <div style={{ border: "1px dashed #001529", padding: 20, borderRadius: 5 }}>

			{/*Save Entries Goes Here*/}
			
			<section>
				{addonEntries.map((addonEntry, addonIndex) => (
					<Space.Compact 
						key={addonIndex+addonIndex+addonEntry.addon} 
						style={{ marginRight: 8, marginBottom: 8 }}
					> 				  
					  <Button
						  size={size}
						  type={"default"}
						  className="site-input-left buttonText"
						  style={{
							  pointerEvents: 'none',
							  backgroundColor: 'transparent',
							  color: '#0ec8b1',
							  borderColor: editIndex === addonIndex ? '#001529' : ''
						  }}
						  disabled
					  >
						{addonEntry.addon}
					  </Button>
					  <Button
						  icon={<EditOutlined />}
						  size={size}
						  id={addonIndex+'edit'}
						  type={"default"}
						  className="site-input-split buttonText"
						  onClick={() => handleEdit(addonIndex, addonEntry.addon)}
						  style={{
							  borderColor: editIndex === addonIndex ? '#001529' : ''
						  }}
					  >
						{`Edit`}
					  </Button>
					  <Button
						  icon={<CopyOutlined />}
						  size={size}
						  id={addonIndex+'copy'}
						  type={"default"}
						  onMouseLeave={() => handleCopyLeave(addonIndex)}
						  className="site-input-split buttonText"
						  onClick={() => handleCopy(addonIndex)}
						  style={{
							  borderColor: editIndex === addonIndex ? '#001529' : ''
						  }}
					  >
						{copied && copyIndex === addonIndex ? 'Copied' : 'Copy'}
					  </Button>
					  <Popconfirm
						  title="Are you sure？"
						  okText="Yes"
						  cancelText="No"
						  onConfirm={() => handleDelete(addonIndex)}
					  >
					  <Button
						  icon={<DeleteOutlined />}
						  size={size}
						  id={addonIndex+'delete'}
						  type={"default"}
						  style={{ marginRight: 8 }}
						  className="site-input-right buttonText"
						  style={{
							  borderColor: editIndex === addonIndex ? '#001529' : ''
						  }}
					  >
						
						{`Delete`}
					  </Button>
					  </Popconfirm>
					</Space.Compact>		
                ))}
			</section>
			
			{addonEntries.length > 0 && (<Divider />)}
			
            <section>
              <Radio.Group
                value={buttonAdd}
				onChange={(e) => handleAddButton(e)}
				size={size}
                style={{ marginBottom: 10 }}
              >
				{addonOptions.map((addonOption, mIndex) => (					
					<ButtonList key={mIndex+addonOption.value} addonVal={addonOption.value} addonLabel={addonOption.label} />)
				)}
              </Radio.Group>
            </section>
			
			<section>
			
			{buttonAdd === "default" && !addon && (			
			  <Fragment>
				<Input
				  style={{ width: 220, marginRight: 10 }}
				  size={size}
				  id="inputAddon"
				  placeholder="Side Name"
				  autoComplete="off"
				/>
				<Button
				  icon={<PlusOutlined />}
				  shape={"circle"}
				  size={size}
				  type={"primary"}
				  style={{ marginRight: 8 }}				  
				  onClick={() => addAddon("inputAddon")}
				/>
				{copyIndex !== '' && (
				<Button
				  icon={<PaperClipOutlined />}
				  shape={"circle"}
				  size={size}
				  type={"dashed"}
				  style={{ marginRight: 8 }}			  
				  onClick={() => handleAttach("inputAddon")}
				/>)}
			  </Fragment>             
            )}
			
			{buttonAdd === "default" && addon && (
			  <Fragment>
				<Button
				  icon={<PlusOutlined />}
				  size={size}
				  type={"primary"}
				  style={{ marginRight: 8 }}
				  onClick={() => addField()}
				>
				{addon}
				</Button>
				<Space.Compact style={{ marginRight: 8 }}>				  
				  <Input
					className="site-input-split site-input-left inputText"
					style={{
					  width: 55,
					  borderLeft: 0,
					  borderRight: 0,
					  pointerEvents: 'none',
					}}
					placeholder="Min"
					disabled
				  />
				  <Input
					size={size}
					className="site-input-right inputText"
					id="addonMin"
					autoComplete="off"
					type={`number`}
					maxLength={2}
					value={min}
					onChange={(event) => setMin(event.target.value)}
					style={{
					  width: 100,
					  textAlign: 'center',
					}}
					placeholder="Min"
				  />
				</Space.Compact>				
				<Space.Compact>				  
				  <Input
					className="site-input-split site-input-left inputText"
					style={{
					  width: 55,
					  borderLeft: 0,
					  borderRight: 0,
					  pointerEvents: 'none',
					}}
					placeholder="Max"
					disabled
				  />
				  <Input
					size={size}
					className="site-input-right inputText"
					id="addonMax"
					type={`number`}
					maxLength={2}
					autoComplete="off"
					value={max}
					onChange={(event) => setMax(event.target.value)}
					style={{
					  width: 100,
					  textAlign: 'center',
					}}
					placeholder="Max"
				  />
				</Space.Compact>
			  </Fragment>           
            )}
			
			{buttonAdd !== "default" && (
			  <Fragment>
				<Button
				  icon={<PlusOutlined />}
				  size={size}
				  type={"primary"}
				  style={{ marginRight: 8 }}
				  onClick={() => addField()}
				>
				{buttonAdd}
				</Button>
				<Space.Compact style={{ marginRight: 8 }}>				  
				  <Input
					className="site-input-split site-input-left inputText"
					style={{
					  width: 55,
					  borderLeft: 0,
					  borderRight: 0,
					  pointerEvents: 'none',
					}}
					placeholder="Min"
					disabled
				  />
				  <Input
					size={size}
					className="site-input-right inputText"
					id="addonMin"
					autoComplete="off"
					value={min}
					type={`number`}
					maxLength={2}
					onChange={(event) => setMin(event.target.value)}
					style={{
					  width: 100,
					  textAlign: 'center',
					}}
					placeholder="Min"
				  />
				</Space.Compact>				
				<Space.Compact>				  
				  <Input
					className="site-input-split site-input-left inputText"
					style={{
					  width: 55,
					  borderLeft: 0,
					  borderRight: 0,
					  pointerEvents: 'none',
					}}
					placeholder="Max"
					disabled
				  />
				  <Input
					size={size}
					className="site-input-right inputText"
					id="addonMax"
					autoComplete="off"
					value={max}
					type={`number`}
					maxLength={2}
					onChange={(event) => setMax(event.target.value)}
					style={{
					  width: 100,
					  textAlign: 'center',
					}}
					placeholder="Max"
				  />
				</Space.Compact>
			  </Fragment>            
            )}
			
            {buttonAdd === "default" && !addon && inputError && <div style={{ color: "#ff4d4f" }}>{inputError}</div>}		
			
			</section>
			
            <div 
				style={{ 
					marginTop: 15, 
					display: 'none'
				}} 
				id="addonform"
			>
			
			  <Button
                  size={size}
                  type={"text"}
				  danger
                  style={{ marginRight: 8, marginBottom: 8 }}
              >
				OPTIONS
			  </Button>
			  
              {fields?.map((field, index) => (
                <div 
					key={index} 
					style={{ marginBottom: "15px" }}
				>
				  <div style={{ 
				    display: "flex", 
				    marginBottom: "5px"}}
				  >
					<section
					  style={{ marginRight: 20 }}
					  rules={[
						{
						  required: true,
						  message: "Please input an option!",
						},
					  ]}
					>
					  <Input
						placeholder="Option"
						value={field.option}
						size={size}
						name={`option-${index}`}
						id={`option-${index}`}
						style={{borderColor: Colors.parentField}}
						autoComplete="off"
						onChange={(event) => handleOptionChange(index, event)}
					  />
					</section>
					<section
					  style={{ marginRight: 20 }}
					  rules={[
						{
						  required: true,
						  message: "Please input an amount!",
						},
					  ]}
					>
					  <Input
						placeholder="Amount"
						value={field.amount}
						size={size}
						name={`amount-${index}`}
						id={`amount-${index}`}
						type={`number`}
						maxLength={10}
						style={{width: 150}}
						autoComplete="off"
						onChange={(event) => handleAmountChange(index, event)}
					  />
					</section>
					<Button
					  icon={<PlusOutlined />}
					  shape={"circle"}
					  size={size}
					  type={"primary"}
					  style={{ marginRight: 8 }}
					  onClick={() => addSubField(index)}
					/>
					<Popconfirm
					  title="Are you sure？"
					  okText="Yes"
					  cancelText="No"
					  onConfirm={() => minusField(index)}
					>
					  <Button
						  icon={<DeleteOutlined />}
						  shape={"circle"}
						  size={size}
						  type={"primary"}
						  danger
						  style={{ marginRight: 8 }}
					  />
					</Popconfirm>
					<Button
					  icon={<CopyOutlined />}
					  shape={"circle"}
					  size={size}
					  type={"primary"}
					  style={{ marginRight: 8 }}
					  onClick={() => copyField(index)}
					/>
					<Button
					  icon={<PaperClipOutlined />}
					  shape={"circle"}
					  size={size}
					  type={"primary"}
					  style={{ marginRight: 8 }}
					  onClick={() => attachField(index)}
					/>					
				  </div>
				  
                  {field?.subFields?.map((subField, subIndex) => (
                    <div
                      key={subIndex}
                      style={{ marginLeft: "10px", marginBottom: "15px" }}
                    >					
                      <div key={index+subIndex} 
					    style={{ display: "flex", 
						marginBottom: "5px" }}
					  >
						<section
						  style={{ marginRight: 20 }}
						  rules={[
							{
							  required: true,
							  message: "Please input an option!",
							},
						  ]}
						>
						  <Input
							placeholder="Option"
							value={subField.option}
							size={size}
							name={`subOption-${index}-${subIndex}`}
							id={`subOption-${index}-${subIndex}`}
							style={{width: 225, borderColor: Colors.childField}}
							autoComplete="off"
							onChange={(event) => handleSubOptionChange(index, subIndex, event)}
						  />
						</section>
						<section
						  style={{ marginRight: 20 }}
						  rules={[
							{
							  required: true,
							  message: "Please input an amount!",
							},
						  ]}
						>
						  <Input
							placeholder="Amount"
							value={subField.amount}
							size={size}
							name={`subAmount-${index}-${subIndex}`}
							id={`subAmount-${index}-${subIndex}`}
							type={`number`}
							maxLength={10}
							style={{width: 150}}
							autoComplete="off"
							onChange={(event) => handleSubAmountChange(index, subIndex, event)}
						  />
						</section>
						<section
						  style={{ marginRight: 20 }}
						  rules={[
							{
							  required: true,
							  message: "Please input description",
							},
						  ]}
						>
						  <Input
							placeholder="Description"
							value={subField.description}
							size={size}
							name={`subDescription-${index}-${subIndex}`}
							id={`subDescription-${index}-${subIndex}`}
							style={{width: 225}}
							autoComplete="off"
							onChange={(event) => handleSubDescriptionChange(index, subIndex, event)}
						  />
						</section>
						<section
						  style={{ marginRight: 20 }}
						  rules={[
							{
							  required: true,
							  message: "Please input a min value!",
							},
						  ]}
						>
						  <Input
							placeholder="Min"
							value={subField.min}
							size={size}
							name={`subMin-${index}-${subIndex}`}
							id={`subMin-${index}-${subIndex}`}
							type={`number`}
							maxLength={10}
							style={{width: 100}}
							autoComplete="off"
							onChange={(event) => handleSubMinChange(index, subIndex, event)}
						  />
						</section>
						<section
						  style={{ marginRight: 20 }}
						  rules={[
							{
							  required: true,
							  message: "Please input maximum required!",
							},
						  ]}
						>
						  <Input
							placeholder="Max"
							value={subField.max}
							size={size}
							name={`subMax-${index}-${subIndex}`}
							id={`subMax-${index}-${subIndex}`}
							type={`number`}
							maxLength={10}
							style={{width: 100}}
							autoComplete="off"
							onChange={(event) => handleSubMaxChange(index, subIndex, event)}
						  />
						</section>
						<Button
						  icon={<PlusOutlined />}
						  shape={"circle"}
						  size={size}
						  style={{ marginRight: 8 }}
						  onClick={() => addSubSubField(index, subIndex)}
						/>						
						<Popconfirm
						  title="Are you sure？"
						  okText="Yes"
						  cancelText="No"
						  onConfirm={() => minusSubField(index, subIndex)}
						>
						  <Button
							  icon={<DeleteOutlined />}
							  shape={"circle"}
							  size={size}
							  danger
							  style={{ marginRight: 8 }}
						  />
						</Popconfirm>
					  </div>			  
                      {subField?.subSubFields?.map((subSubField, subSubIndex) => (
                        <div
						  key={subSubIndex}
						  style={{
							marginLeft: "10px",
							marginBottom: "10px",						
						  }}
						>				
						<div 
							key={index+subIndex} 
							style={{ display: "flex", marginBottom: "5px" }}
						>						
						
						  <section
							style={{ marginRight: 20 }}
							rules={[
							  {
								required: true,
								message: "Please input an option!",
							  },
							]}
						  >
							<Input
							  placeholder="Option"
							  value={subSubField.option}
							  size={size}
							  name={`subSubOption-${index}-${subIndex}-${subSubIndex}`}
							  id={`subSubOption-${index}-${subIndex}-${subSubIndex}`}
							  style={{width: 225, borderColor: Colors.grandChildField}}
							  autoComplete="off"
							  onChange={(event) =>
								handleSubSubOptionChange(
								  index,
								  subIndex,
								  subSubIndex,
								  event
								)
							  }
							/>
						  </section>
						  <section
							style={{ marginRight: 20 }}
							rules={[
							  {
								required: true,
								message: "Please input an amount!",
							  },
							]}
						  >
							<Input
							  placeholder="Amount"
							  value={subSubField.amount}
							  size={size}
							  name={`subSubAmount-${index}-${subIndex}-${subSubIndex}`}
							  id={`subSubAmount-${index}-${subIndex}-${subSubIndex}`}
							  type={`number`}
							  maxLength={10}
							  style={{width: 150}}
							  autoComplete="off"
							  onChange={(event) =>
								handleSubSubAmountChange(
								  index,
								  subIndex,
								  subSubIndex,
								  event
								)
							  }
							/>
						  </section>
						  <section
							style={{ marginRight: 20 }}
							rules={[
							  {
								required: true,
								message: "Please input description!",
							  },
							]}
						  >
							<Input
							  placeholder="Description"
							  value={subSubField.description}
							  size={size}
							  name={`subSubDescription-${index}-${subIndex}-${subSubIndex}`}
							  id={`subSubDescription-${index}-${subIndex}-${subSubIndex}`}
							  style={{width: 225}}
							  autoComplete="off"
							  onChange={(event) =>
								handleSubSubDescriptionChange(
								  index,
								  subIndex,
								  subSubIndex,
								  event
								)
							  }
							/>
						  </section>
						  <section
							style={{ marginRight: 20 }}
							rules={[
							  {
								required: true,
								message: "Please input minimum required!",
							  },
							]}
						  >
							<Input
							  placeholder="Min"
							  value={subSubField.min}
							  size={size}
							  name={`subSubMin-${index}-${subIndex}-${subSubIndex}`}
							  id={`subSubMin-${index}-${subIndex}-${subSubIndex}`}
							  type={`number`}
							  maxLength={10}
							  style={{width: 100}}
							  autoComplete="off"
							  onChange={(event) =>
								handleSubSubMinChange(
								  index,
								  subIndex,
								  subSubIndex,
								  event
								)
							  }
							/>
						  </section>
						  <section
							style={{ marginRight: 20 }}
							rules={[
							  {
								required: true,
								message: "Please input maximum required!",
							  },
							]}
						  >
							<Input
							  placeholder="Max"
							  value={subSubField.max}
							  size={size}
							  name={`subSubMax-${index}-${subIndex}-${subSubIndex}`}
							  id={`subSubMax-${index}-${subIndex}-${subSubIndex}`}
							  type={`number`}
							  maxLength={10}
							  style={{width: 100}}
							  autoComplete="off"
							  onChange={(event) =>
								handleSubSubMaxChange(
								  index,
								  subIndex,
								  subSubIndex,
								  event
								)
							  }
							/>
						  </section>
						  <Button
							  icon={<PlusOutlined />}
							  shape={"circle"}
							  size={size}
							  type={"text"}
							  style={{ marginRight: 8 }}
							  onClick={() => addSubSubSubField(index, subIndex, subSubIndex)}
						  />	
						  <Popconfirm
							  title="Are you sure？"
							  okText="Yes"
							  cancelText="No"
							  onConfirm={() => minusSubSubField(index, subIndex, subSubIndex)}
						  >
							  <Button
								  icon={<DeleteOutlined />}
								  shape={"circle"}
								  size={size}
								  type={"text"}
								  danger
								  style={{ marginRight: 8 }}
							  />
						  </Popconfirm>
						  </div>
						  {subSubField?.subSubSubFields?.map((subSubSubField, subSubSubIndex) => (
							<div
							  key={subSubSubIndex}
							  style={{
								marginLeft: "10px",
								marginBottom: "15px",
								display: "flex",						
							  }}
							>							
							  <section
								style={{ marginRight: 20 }}
								rules={[
								  {
									required: true,
									message: "Please input an option!",
								  },
								]}
							  >
								<Input
								  placeholder="Option"
								  value={subSubSubField.option}
								  size={size}
								  name={`subSubSubOption-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  id={`subSubSubOption-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  style={{width: 225, borderColor: Colors.greatGrandChildField}}
								  autoComplete="off"
								  onChange={(event) =>
									handleSubSubSubOptionChange(
									  index,
									  subIndex,
									  subSubIndex,
									  subSubSubIndex,
									  event
									)
								  }
								/>
							  </section>
							  <section
								style={{ marginRight: 20 }}
								rules={[
								  {
									required: true,
									message: "Please input an amount!",
								  },
								]}
							  >
								<Input
								  placeholder="Amount"
								  value={subSubSubField.amount}
								  size={size}
								  name={`subSubSubAmount-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  id={`subSubSubAmount-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  type={`number`}
								  maxLength={10}
								  style={{width: 150}}
								  autoComplete="off"
								  onChange={(event) =>
									handleSubSubSubAmountChange(
									  index,
									  subIndex,
									  subSubIndex,
									  subSubSubIndex,
									  event
									)
								  }
								/>
							  </section>
							  <section
								style={{ marginRight: 20 }}
								rules={[
								  {
									required: true,
									message: "Please input description!",
								  },
								]}
							  >
								<Input
								  placeholder="Description"
								  value={subSubSubField.description}
								  size={size}
								  name={`subSubSubDescription-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  id={`subSubSubDescription-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  style={{width: 225}}
								  autoComplete="off"
								  onChange={(event) =>
									handleSubSubSubDescriptionChange(
									  index,
									  subIndex,
									  subSubIndex,
									  subSubSubIndex,
									  event
									)
								  }
								/>
							  </section>
							  <section
								style={{ marginRight: 20 }}
								rules={[
								  {
									required: true,
									message: "Please input minimum required!",
								  },
								]}
							  >
								<Input
								  placeholder="Min"
								  value={subSubSubField.min}
								  size={size}
								  name={`subSubSubMin-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  id={`subSubSubMin-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  type={`number`}
								  maxLength={10}
								  style={{width: 100}}
								  autoComplete="off"
								  onChange={(event) =>
									handleSubSubSubMinChange(
									  index,
									  subIndex,
									  subSubIndex,
									  subSubSubIndex,
									  event
									)
								  }
								/>
							  </section>
							  <section
								style={{ marginRight: 20 }}
								rules={[
								  {
									required: true,
									message: "Please input maximum required!",
								  },
								]}
							  >
								<Input
								  placeholder="Max"
								  value={subSubSubField.max}
								  size={size}
								  name={`subSubSubMax-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  id={`subSubSubMax-${index}-${subIndex}-${subSubIndex}-${subSubSubIndex}`}
								  type={`number`}
								  maxLength={10}
								  style={{width: 100}}
								  autoComplete="off"
								  onChange={(event) =>
									handleSubSubSubMaxChange(
									  index,
									  subIndex,
									  subSubIndex,
									  subSubSubIndex,
									  event
									)
								  }
								/>
							  </section>
							  
							  <Popconfirm
								  title="Are you sure？"
								  okText="Yes"
								  cancelText="No"
								  onConfirm={() => minusSubSubSubField(index, subIndex, subSubIndex, subSubSubIndex )}
							  >
								  <Button
									  icon={<DeleteOutlined />}
									  shape={"circle"}
									  size={size}
									  type={"dashed"}
									  danger
									  style={{ marginRight: 8 }}
								  />
							  </Popconfirm>
							  </div>
						  
						  ))}
						</div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}			  
			  <section>
				  <Button
					type="primary"
					icon={<SaveOutlined />}
					style={{ backgroundColor: '#001529' }}
					size={size}
					onClick={() => onSaveOptions("addonform")}
				  >
					Save Options
				  </Button>
			  </section>	  
            </div>			
          </div>		  
        ),
        rowExpandable: (record) => record.info !== "Not Expandable",
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <MinusOutlined
              style={{ fontSize: "20px" }}
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <PlusOutlined
              style={{ fontSize: "20px" }}
              onClick={(e) => onExpand(record, e)}
            />
          ),
      }}
      //pagination={{position: ["bottomCenter"]}}
      //bordered
	  //footer={() => (<Button onClick={addUser}><PlusOutlined /> Add User</Button>})}
    >
      <Column
        dataIndex={"category"}
        title={"Category"}
        width={"17%"}
        render={(value, row, index) => {
          return (
            <AddFormItem
              rules={[{ required: true, message: "Category is required" }]}
              name={[index, "category"]}
              editing={index === editingIndex}
              className={"ant-form-item-no-bottom-margin"}
            >
              <Select 
				placeholder="Select a category" 
				style={{minWidth: 250}}
				className="tags-editor"
				size={size}
				dropdownRender={(menu) => (
					<>
					  {menu}
					  <Divider style={{ margin: '8px 0' }} />
					  <Space style={{ padding: '0 8px 4px' }}>
						<Input
						  placeholder="New Category"
						  ref={inputRef}
						  value={name}
						  size={size}
						  onChange={onNameChange}
						  
						/>
						<Button 
							type="text"
							size={size}
							icon={<PlusOutlined />} 
							onClick={addItem}
						>
						  Add
						</Button>
					  </Space>
					</>
				)}
				options={items.map((item) => ({ label: item.label, value: item.value }))}
			  />
            </AddFormItem>
          );
        }}
      />
      <Column
        dataIndex={"file"}
        title={"Image"}
        width={"15%"}
        render={(value, row, index) => {			
          return (
            <AddFormItem
              name={[index, "file"]}
              editing={index === editingIndex}
              className={"ant-form-item-no-bottom-margin"}
            >
				
			<span>
			{/*<span>
			    <Input placeholder="image" size={size} value="itemImage" value={itemImage} onChange={(e) => setItemImage(e.target.value)} onMouseDown={openFileModal} onKeyUp={openFileModal} onKeyPress={openFileModal} />
				<input className="hidden-file" name="file" type="file" id="image_file" />	
			</span>*/}
			  {fileName && (
				<div>
				<img 
				  src={`${blobUrl}`} 
				  className="avatar-uploader" 
				  alt="avatar" 
				  style={{ width: '70%' }}
				/>
				<br/>
				<span style={{fontSize: 16}}>{fileName}</span>
				<br/>
				<span 
					style={{ 
						color: 'red', 
						borderBottomWidth: 1, 
						cursor: 'pointer' 
					}} 
					onClick={()=> removeFile()} 
				>
				Remove				
				</span>
				</div>
			  )}
			  
			  {!fileName && (
			  <Upload				
			    name="avatar"
				listType="picture-card"				
				className="avatar-uploader"
				action=""
				beforeUpload={beforeUpload}
				onChange={handleUploadChange}
			  >
				<div>
					{loading ? <LoadingOutlined /> : <PlusOutlined />}
					<div style={{ marginTop: 8 }}>Upload</div>		
				</div>				
			  </Upload>
			  )}
			</span>
		   </AddFormItem>
          );
        }}
      />
      <Column
        dataIndex={"name"}
        title={"Name"}
        width={"17%"}
        render={(value, row, index) => {			
          return (
            <AddFormItem
              rules={[{ required: true, message: "Name is required" }]}
              name={[index, "name"]}
              editing={index === editingIndex}
              className={"ant-form-item-no-bottom-margin"}
            >
              <Input placeholder="name" size={size} className="tags-editor" style={{minWidth: 200}}/>
            </AddFormItem>
          );
        }}
      />
      <Column
        dataIndex={"description"}
        title={"Description"}
        width={"23%"}
        render={(value, row, index) => {
          return (
            <AddFormItem
              rules={[{ required: true, message: "Description is required" }]}
              name={[index, "description"]}
              editing={index === editingIndex}
              className={"ant-form-item-no-bottom-margin"}
            >
              <Input.TextArea placeholder="description" size={size} className="tags-editor" style={{minWidth: 200}}/>
            </AddFormItem>
          );
        }}
      />
      <Column
        dataIndex={"price"}
        title={"Price"}
        width={"10%"}
        render={(value, row, index) => {
          return (
            <AddFormItem
              rules={[{ required: true, message: "Price is required" }]}
              name={[index, "price"]}
              editing={index === editingIndex}
              className={"ant-form-item-no-bottom-margin"}
            >
              <InputNumber placeholder="price" size={size} className="tags-editor" style={{minWidth: 100}}/>
            </AddFormItem>
          );
        }}
      />
      <Column
        title={"Action"}
		style={{minWidth: 250}}
        render={(value, row, index) => {
          if (index === editingIndex) {
            return (
              <Fragment>
                <Button
                  icon={<SaveOutlined />}
                  shape={"circle"}
                  type={"primary"}
                  size={size}
                  style={{ marginRight: 8 }}
                  onClick={onSave}
                />
                <Button
                  icon={<CloseOutlined />}
                  shape={"circle"}
                  size={size}
                  onClick={() => onCancel(index)}
                />
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Button
                  icon={<EditOutlined />}
                  shape={"circle"}
                  size={size}
                  type={"primary"}
                  style={{ marginRight: 8 }}
                  disabled={editingIndex !== undefined}
                  onClick={() => setEditingIndex(index)}
                />
                {/*<Popconfirm
                  title="Are you sure？"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => remove(index)}
                  disabled={editingIndex !== undefined}
                >
                  <Button
                    icon={<MinusOutlined />}
                    shape={"circle"}
                    size={size}
                    danger
                    disabled={editingIndex !== undefined}
                  />
                </Popconfirm>*/}
              </Fragment>
            );
          }
        }}
      />
    </Table>
  );
};
