import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { utils } from "../../Utils";
import { Counter } from "../../Components";
import TopTotal from "./TopTotal";
import RouteNav from "./RouteNav";
import LatestOrder from "./LatestOrder";
import OrderManagement from "./OrderManagement";
import ProductManagement from "./ProductManagement";

const Home = ({pcount, initOrders, params}) => {	
	
	const email = localStorage.getItem('useremail');
	
	const makeExit = () =>{
	if(window.confirm(`Do you still want to logout\n\n${email}?`)){
			localStorage.clear();
			window.location.assign("/");	  
			return false;
		}
	}
  
	return (
    <>
      <section className="content-main">
        <div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
			<div className="content-header1">			
			{/*<div><div onClick={()=> makeExit()} className="btn btn-danger">Log out</div></div>*/}
			</div>			
			<Counter pcount={pcount} />
		</div>
		
		
		
        {/* Top Total */}
        <TopTotal params={params}/>

        <div className="row">
          {/* STATICS */}
          <OrderManagement />
          <ProductManagement />
        </div>
		
		{/* Route Navigation */}
        <RouteNav />

        {/* LATEST ORDER */}
        <div className="card mb-4 shadow-sm">
          <LatestOrder initOrders={initOrders}/>
        </div>
      </section>
    </>
  );
};

export default Home;
