import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { AddItemScreen, ItemScreen, EditItemScreen, LoginScreen, ForgotPasswordScreen, OrderScreen, OrderDetailScreen, NewOrderScreen, CategoryScreen, ChangePasswordScreen, StoreScreen, AddStoreScreen, SubscriptionScreen, NotFoundScreen, OrderListScreen, HomeScreen, SupportScreen } from "./Screens";
import { SideBar } from "./Components";
import { utils } from "./Utils";
import { v4 as uuidv4 } from "uuid";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./Store";
import { Layout, Breadcrumb, Divider } from "antd";
import mySound from "./Audio/alert.mp3";

const App = () => {	
	
	const [target, setTarget] = useState('');
	const [startpoint, setStartPoint] = useState('');
	const [mainstage, setMainStage] = useState('');
	const [mstartpoint, setMStartPoint] = useState('');
	const [mstage, setMStage] = useState('');
	const [loaded, setLoaded] = useState(false);
	const [flash, setFlash] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	
	const [modalTitle, setModalTitle] = useState('');
	const [modalBody, setModalBody] = useState('');
	const [modalButton, setModalButton] = useState(false);
	const [modalButtonFunc, setModalButtonFunc] = useState();
	const [modalPage, setModalPage] = useState('');
	const [pageYear, setPageYear] = useState('2023');

	
	const [user, setUser] = useState();
	const [hqId, setHqId] = useState();	
	const [audioStatus, setAudioStatus] = useState(false);
	const myRef = useRef();

	const [params, setParams] = useState({});
	const [orders, setOrders] = useState([]);
	const [npass, setNpass] = useState(false);	
	const [rpass, setRpass] = useState(false);
	const [click, setClick] = useState(false);
	const [update, setUpdate] = useState(false);
	const [update2, setUpdate2] = useState(false);
	const [updatedelete, setUpdateDelete] = useState(false);
	const [updatecategory, setUpdateCategory] = useState(false);
	const [updateresponse, setUpdateResponse] = useState(false);
	const [audioPlay, setAudioPlay] = useState(false);
	
	const [clear, setClear] = useState(false);	
	const [pcount, setPcount] = useState('');
	const merchantCALL = utils.MERCHANT_CALL;		
	
	
	
	useEffect(() => {	  
	  if (loaded && localStorage.getItem('userstoreId')) {
		const interval = setInterval(() => {
		  fetchOrders();
		}, 10000);
		return () => clearInterval(interval);
	  }
	}, [loaded]);
	
	useEffect(() => {	  
	  const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		setPageYear(currentYear);
	}, []);
	
	useEffect(() => 
	{	
	  if (localStorage.getItem('userstoreId')) {
		const interval = setTimeout(() => {
		  fetchOrders();
		}, 100);
		return () => clearTimeout(interval); 
	  }
		// eslint-disable-next-line
	}, [user, pcount]);	
	
	
	useEffect(() => 
	{		
		let useremail = '';
		if(localStorage.getItem('useremail'))
		{
			useremail = localStorage.getItem('useremail');
		}
		
		let mainStoreId = '';
		if(localStorage.getItem('userstoreId'))
		{
			mainStoreId = localStorage.getItem('userstoreId');
		}
		if(useremail === 'undefined'){
			localStorage.clear();
		}else if(mainStoreId === 'undefined'){
			localStorage.clear();
		}
		
		if(utils.isValidEmail(useremail)){
			setUser(useremail);
			setHqId(mainStoreId);
		}
		// eslint-disable-next-line
	}, []);
		
	
	useEffect(() => 
	{		
		const getURL2 = async () => {	
			var urln = window.location.pathname;
			var allowed = ['login', 'dashboard', 'products', 'forgotpassword', 'changepassword', 'bankdetails', 'profile', 'orders', 'neworders', 'category', 'storecategory', 'store', 'help', 'subscription', 'addItem', 'editItem'];
			var thisr = urln.replace("/", "");
			thisr = (urln.replace(/[^A-Z0-9]/i, "")).toUpperCase();
			if(allowed.indexOf(thisr.toLowerCase()) !== -1)
			{
				//setDlink(thisr.toLowerCase());
			}
		}
		
		setInterval(getURL2, 250);
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => 
	{		
	  if(utils.isValidEmail(user)){
		  getParams(user);
	  }
		// eslint-disable-next-line
	}, [user]);
	
	useEffect(() => 
	{		
	  if(utils.isValidEmail(user)){
		  getParams(user);
	  }
		// eslint-disable-next-line
	}, [update2]);	
	
	useEffect(() => {
    const requestPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //console.log('Audio permission granted');
        // Do something with the audio stream
				setAudioPlay(true);
      } catch (error) {
				setAudioPlay(false);
				alert('Audio permission is required');
        //console.log('Error requesting audio permission:', error.message);
      }
    };

    requestPermission();
  }, []);
			
	const getParams = async (email) => {		
		const paramsFromServer = await utils.fetchStoreUser(email);
		setParams(paramsFromServer);
		localStorage.setItem('useremail', paramsFromServer?.email);
		localStorage.setItem('userstoreId', paramsFromServer?.hqId);
		localStorage.setItem('helpmessage', paramsFromServer?.helpmessage);
		localStorage.setItem('helpphone', paramsFromServer?.helpphone);
		setLoaded(true);
		setUpdate2(true);
	}			
	
	const fetchOrders = async () => 
	{
		try{
			if(!localStorage.getItem('userstoreId')){
				return;
			}
			const storeId = await localStorage.getItem('userstoreId');
			
			/*const header = await utils.genHeader();
			const target = merchantCALL+'?request=order&hqId='+storeId;
			const res = await utils.fetchURL(target, 'GET', '', header);
			const data = await res.json();
			setOrders(data);*/
			
			if(storeId === undefined){return;}
			let ordersFromServer = await utils.fetchHqIdOrder(storeId);
			let orderlen = ordersFromServer.length;
			const orderData = await utils.cipherEncode(JSON.stringify(ordersFromServer));
			await localStorage.setItem('__el_ord', orderData);
			const orderInfo = await utils.cipherDecode(orderData);
			//dispatch(setOrders(orderInfo));
			setOrders(orderInfo);
			
			let islen = 0;
			let isaudio = 0;
			let orderstatus , offers; 
			for(let i=0; i<orderlen; i++)
			{
				offers = ordersFromServer[i].data;
				orderstatus = ordersFromServer[i].storestatus;
				
				if(offers.length > 0 && orderstatus === 'NEW')
				{						
					isaudio++;
				}
				
				if(offers.length > 0 && orderstatus !== 'STORE_COMPLETED')
				{
					islen++;
				}
			}
			
			if(document.getElementById('pauseaudio'))
			{				
				if(isaudio > 0 && islen >= 0)
				{
					//Play Audio
					setPcount(islen);					
					if(document.getElementById('playaudio'))
					{
						//console.log('H Play Audio');
						let urln = window.location.pathname;
						let thisr = urln.replace("/", "");
						if(thisr !== 'new-orders'){
							window.location.pathname = '/new-orders';
						}
						setFlash(true);
						audioPlay && document.getElementById('playaudio').click();
					}
				}
				else if(isaudio === 0 && islen > 0)
				{					
					//stop audio
					setPcount(islen);
					//console.log('H Pause Audio');
					setFlash(false);
					audioPlay && document.getElementById('pauseaudio').click();
				}
				
				else if(isaudio === 0 && islen === 0)
				{
					//stop audio
					setPcount('');
					setFlash(false);
					//console.log('H Pause Audio2');
					audioPlay && document.getElementById('pauseaudio').click();
				}
				else if(audioStatus)
				{
					//stop audio
					setPcount('');
					setFlash(false);
					//console.log('H Pause Audio3');
					audioPlay && document.getElementById('pauseaudio').click();
				}			
			}
		
		}catch(e){}		
	}
	
	
	const startAudio = async() => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			myRef.current.loop = true;
			myRef.current.play();		
			setAudioStatus(true);
		} catch (error) {}		
	};

	const pauseAudio = async() => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			myRef.current.loop = false;
			myRef.current.pause();		
			setAudioStatus(false);
		} catch (error) {}
	};
	
			
	function cookieSet(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function cookieGet(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
	
	function cookieCheck(cname){
		var color = cookieGet(cname);
		if(color){
			return color;
		}else{
			cookieSet(cname, 'dark', 30);
			return 'dark';
		}
	}
	
	const { Header, Footer } = Layout;	
	
	if(user && hqId){
		return (
			<Provider store={store}>	
			<Router>
			  <AnimatePresence mode='wait'>
				<Layout style={{ minHeight: "100vh", }}>
				  <SideBar />
				  <Layout
					className={`site-layout-sub-header-background ${flash ? ' flashing' : ''}`}
					style={{
					  backgroundColor: "#fbfcfa",
					}}
				  >
					<Header className="site-layout-background" style={{ padding: 0 }} />
					<Routes>
					  {user && hqId && (<Route exact path="/" element={<HomeScreen pcount={pcount} key={uuidv4()} initOrders={orders} params={params}/>} />)}
					  {user && hqId && (<Route path="/new-orders" element={<NewOrderScreen pcount={pcount} setPcount={setPcount} key={uuidv4()} loaded={loaded} initOrders={orders} setFlash={setFlash} params={params} update2={update2} setUpdate2={setUpdate2} />} />)}
					  {user && hqId && (<Route path="/orders" element={<OrderScreen pcount={pcount} initOrders={orders} key={uuidv4()} />} />)}
					  {user && hqId && (<Route path="/order/:orderId" element={<OrderDetailScreen pcount={pcount} setPcount={setPcount} key={uuidv4()} />} />)}
					  {user && hqId && (<Route path="/add-item" element={<AddItemScreen pcount={pcount} key={uuidv4()} params={params} loaded={loaded} />} />)}
					  {user && hqId && (<Route path="/edit-item/:itemId" element={<EditItemScreen pcount={pcount} params={params} loaded={loaded} key={uuidv4()} />} />)}
					  {user && hqId && (<Route path="/item-list" element={<ItemScreen pcount={pcount} />} key={uuidv4()} />)}  
					  {user && hqId && (<Route path="/change-password" element={<ChangePasswordScreen pcount={pcount} />} key={uuidv4()} />)}  
					  {user && hqId && (<Route path="/manage-store" element={<StoreScreen pcount={pcount} params={params} />} key={uuidv4()} />)}
					  {user && hqId && (<Route path="/add-new-store" element={<AddStoreScreen pcount={pcount} params={params} />} key={uuidv4()} />)}
					  {user && hqId && (<Route path="/create-category" element={<CategoryScreen pcount={pcount} />} key={uuidv4()} />)}  
					  {user && hqId && (<Route path="/billing" element={<SubscriptionScreen pcount={pcount} />} key={uuidv4()} />)}  
					  {user && hqId && (<Route path="/support" element={<SupportScreen pcount={pcount} />} key={uuidv4()} />)}
					  {user && hqId && (<Route path="*" element={<NotFoundScreen key={uuidv4()} />} />)}  
					</Routes>
					<audio
						ref={myRef}
						src={mySound}
					/>
					<button id="pauseaudio" style={{height:'0.1px', width:'0.1px', left: -1000, position: 'absolute'}} onClick={pauseAudio}></button>
					<button id="playaudio" style={{height:'0.1px', width:'0.1px', left: -1000, position: 'absolute'}} onClick={startAudio}></button>
				
					<Footer
					  style={{
						textAlign: "center",
						backgroundColor: "#fbfcfa",
					  }}
					>
					{`© ${pageYear} ErrandLocal`} 
					</Footer>
			  </Layout>
			</Layout>
			  </AnimatePresence>
			</Router>
			</Provider>	
		);
	}else{
		return (
		<Provider store={store}>	
		<Router>
		  <AnimatePresence mode='wait'>
			<Routes>
			  <Route exact path="/" element={<LoginScreen setOrders={setOrders} key={uuidv4()} />} />		  
			  <Route path="/login" element={<LoginScreen setOrders={setOrders} key={uuidv4()} />} />
			  <Route path="/forgotpassword" element={<ForgotPasswordScreen key={uuidv4()} />} />
			  <Route path="*" element={<NotFoundScreen key={uuidv4()} />} />
			</Routes>				
		  </AnimatePresence>
		</Router>
		</Provider>
		);
	}
	
};

export default App;
