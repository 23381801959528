import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { utils } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { Counter } from "../../Components";

const SupportScreen = ({pcount}) => {
	const navigate = useNavigate();
	const helpmessage = localStorage.getItem('helpmessage');
	const helpphone = localStorage.getItem('helpphone');
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Support", title: "Support" },
	];
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Get Support | Restaurant | ErrandLocal";
	}, []);
	
	
	return (
		<>
		<Content style={{ margin: "24px 16px 0" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Support</h2>
			<span style={{ opacity: 0.5 }}>Should you need to reach out to us? Do not hesitate to use the contact details below.</span>
			<Divider />
			<section className="content-main">
			<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
				<div className="content-header1">			
					<div><div onClick={()=> navigate('/')} className="btn btn-primary">Back to dashboard</div></div>
				</div>
				
				<Counter pcount={pcount} />
			</div>
			<div className="row">
			  <div className="col-lg-4">
				<div className="card card-body mb-4 shadow-sm">
				  <article className="icontext">
					<span className="icon icon-sm rounded-circle alert-primary">
					  <i className="text-primary fas fa-phone"></i>
					</span>
					<div className="text">
						<h6 className="mb-1">Contact Support</h6><span>{`${helpmessage}`}</span><br/><br/><span>{`${utils.convertToUSPhoneFormat(helpphone)}`}</span>
					</div>
				  </article>
				</div>
			  </div>
			</div>
			</section>
		  </div>
		</Content>
		
	</>
	);

};

export default SupportScreen;
