import { configureStore } from "@reduxjs/toolkit";
import navReducer from "./Includes/NavSlice";

export const store = configureStore({
	reducer: {
		nav: navReducer,
	},
});

/*
// Retrieve cart data from local storage
const getCartFromLocalStorage = () => {
  const cartData = localStorage.getItem("cart");
  if (cartData) {
    return JSON.parse(cartData);
  }
  return [];
};


// Create the Redux store
const store = configureStore({
  reducer: {
    nav: navReducer,
  },
  preloadedState: {
    cart: getCartFromLocalStorage(),
  },
});

// Subscribe to local storage updates
window.addEventListener("storage", (event) => {
  if (event.key === "cart") {
    const cartData = JSON.parse(event.newValue);
    store.dispatch({ type: "SET_CART", payload: cartData });
  }
});

export default store;
*/