import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { useNavigate } from "react-router-dom";
import { ChangePassword } from "../../Components";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";

const ChangePasswordScreen = ({pcount}) => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [updatecategory, setUpdateCategory] = useState(false);
	const merchantURL = utils.MERCHANT_URL;
	
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Settings", title: "Settings" },
		{ breadcrumbName: "Change Password", title: "Change Password" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Change Password | Restaurant | ErrandLocal";
	}, []);
	
	const onPostChangePassword = async (param) =>
	{	
		try{
			const header = await utils.genHeader();
			const target = await utils.MERCHANT_URL;
			
			const res = await utils.fetchURL(target, 'POST', JSON.stringify(param), header);			
			const data = await res.json();
			if(data.msg === 'Token Updated'){
				localStorage.setItem('__el_token', data?.token);
				message.info('Please resubmit');
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){
				document.getElementById("feedback").innerHTML = data.msg;
				document.getElementById("feedback").className = 'incsizespan_success center mb-2';
			}else{
				document.getElementById("feedback").innerHTML = data.msg;
				document.getElementById("feedback").className = 'incsizespan_error center mb-2';
			}
		}catch(e){
			document.getElementById("feedback").innerHTML = e.message;		
			document.getElementById("feedback").className = 'incsizespan_error center mb-2';
		}
	}
	
	
	return (
		<Content style={{ margin: "24px 16px 0" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Change Password</h2>
			<span style={{ opacity: 0.5 }}>Fill the form below to change your current password.</span>
			<Divider />
			<ChangePassword pcount={pcount} onPostChangePassword={onPostChangePassword} />
		  </div>
		</Content>
	);
	
};

export default ChangePasswordScreen;
