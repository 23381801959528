import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
	BarsOutlined, 
	PlusOutlined, 
	PlusSquareOutlined, 
	MinusOutlined, 
	MinusSquareOutlined, 
	BorderBottomOutlined,
	BorderTopOutlined,
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	DesktopOutlined,
	MessageOutlined,
	PieChartOutlined,
} from "@ant-design/icons";


const SideBar = () => {
  const navigate = useNavigate();
  
  const { Sider } = Layout;
	
	const email = localStorage.getItem('useremail');
	
	const makeExit = () =>{
	if(window.confirm(`Do you still want to logout\n\n${email}?`)){
			localStorage.clear();
			window.location.assign("/");	  
			return false;
		}
	}
  
  const getItem = (label, key, icon, children, onClick) => {
		return {
			key,
			icon,
			children,
			label,
			onClick
		};
	};

	const items = [
		getItem('Dashboard', uuidv4(), <DesktopOutlined />, null, () => {    navigate('/', { replace: true })}),
		getItem('Orders', uuidv4(), <BarsOutlined />, [
			getItem('New', uuidv4(), null, null, () => {navigate('/new-orders', { replace: true })}), 
			getItem('All Orders', uuidv4(), null, null, () => {navigate('/orders', { replace: true })})
		]),
		getItem('Menu', uuidv4(), <BarsOutlined />, [
			getItem('List', uuidv4(), null, null, () => {navigate('/item-list', { replace: true })}),
			getItem('Create', uuidv4(), null, null, () => {navigate('/add-item', { replace: true })}),
		]),
		getItem('Categories', uuidv4(), <PieChartOutlined />, null, () => {    navigate('/create-category', { replace: true })}),
		getItem('Settings', uuidv4(), <BarsOutlined />, [
			getItem('Manage Store', uuidv4(), null, null, () => {navigate('/manage-store', { replace: true })}), 
			getItem('Change Password', uuidv4(), null, null, () => {navigate('/change-password', { replace: true })}), 
			getItem('Billing', uuidv4(), null, null, () => {navigate('/billing', { replace: true })}),
		]),
		getItem('Support', uuidv4(), <MessageOutlined />, null, () => {navigate('/support', { replace: true })}),
	];
	
	

  return (
    <>
      <Sider 
		//collapsible 
		//collapsed={collapsed} 
		//onCollapse={(value) => setCollapsed(value)}
		style={{					
			//overflow: 'auto',
			//height: '100vh',
			//position: 'fixed',
			//left: 0,
			//top: 0,
			//bottom: 0,
			//zIndex: 500,
			
		}}
		breakpoint="lg"
		collapsedWidth="0"
		onBreakpoint={(broken) => {
			//console.log(broken);
		}}
		onCollapse={(collapsed, type) => {
			//console.log(collapsed, type);
		}}
	>
		<div className="logo">
		<img 
			src={require('../../logo.png')} 
			style={{
				position: 'absolute',
				width: 'auto',
				height: 70,
				borderRadius: 35,
			}}
			alt={`Logo`}
		/>
		</div>
		<Menu 
			theme="dark" 
			defaultSelectedKeys={['4']} 
			mode="inline" 
			items={items}
			onClick={(item) => {
				if (item.onClick) {
				  item.onClick();
				}
			}}
		/>
		<br/>
		<div className="content-header1">			
			<div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><div onClick={()=> makeExit()} className="btn btn-danger" style={{color: '#ffffff'}}>Log Out</div></div>
		</div>
	</Sider>
    </>
  );
};

export default SideBar;