import React from "react";
import { useNavigate } from "react-router-dom";


const OrderManagement = () => {
  const navigate = useNavigate();
  
  return (
    <div className="col-xl-6 col-lg-12">
      <div className="card mb-4 shadow-sm">
        <article className="card-body">
          <h5 className="card-title">Order Management</h5>
          <span>Manage your orders</span>. <span style={{color: '#3888fd', cursor: 'pointer', textDecoration: 'underline'}} onClick={()=> navigate('/orders', { replace: true })}>Continue</span>
        </article>
      </div>
    </div>
  );
};

export default OrderManagement;
