import React from "react";
import OrderDetailItems from "./OrderDetailItems";
import OrderDetailInfo from "./OrderDetailInfo";
import { Link, useNavigate } from "react-router-dom";
import { utils } from "../../Utils";
import { Counter } from "../../Components";

const OrderDetail = ({ pcount, orderdata, onPostStatus }) => {
	
	const navigate = useNavigate();
	const storeId = localStorage.getItem('userstoreId');
	const email = localStorage.getItem('useremail');
	
	const updateStatus = (state) => {
		if(window.confirm('Are you sure want to update the status?')){
			var output = {
				'rtype': 'updatestatus',
				'hqId': storeId,
				'storeId': orderdata[0].storeId,
				'email': email,
				'orderId': orderdata[0]?.orderId,
				'level': state,
			}
			onPostStatus(output);
		}
	}
	
	
	return (
    <section className="content-main">
	  <div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
		<div className="content-header1">			
			<div><div onClick={()=> navigate("/orders")} className="btn btn-primary">Back to orders</div></div>
        </div>
		
		<Counter pcount={pcount} />
	  </div>
   

      <div className="card">
        <header className="card-header p-3 Header-green" style={{backgroundColor: '#001529', }}>
          <div className="row align-items-center ">
            <div className="col-lg-6 col-md-6">
              <h4 className="text-white mx-3 text-white">
                <i className="far fa-calendar-alt mx-2"></i>
                {utils.dateToString(orderdata[0]?.createdTimestamp, 'date')}
              </h4>
              <br />
              <h5 className="text-white mx-3 text-white">
                Order #: <b>{orderdata[0]?.orderId}</b>
              </h5>
            </div>
            <div className="col-lg-6 col-md-6 ms-auto d-flex justify-content-end align-items-center">
              <Link className="btn btn-success ms-2" to="#">
                <i className="fas fa-print"></i>
              </Link>
            </div>
          </div>
        </header>
        <div className="card-body">
          {/* Order info */}
          <OrderDetailInfo orderdata={orderdata} />

          <div className="row">
            <div className="col-lg-9">
              <div className="table-responsive">
                <OrderDetailItems orderdata={orderdata} />
              </div>
            </div>
            {/* Payment Info */}
            <div className="col-lg-3">            
			  {orderdata[0]?.storestatus === 'STORE_COMPLETED' ? (<div className="box shadow-sm bg-light mb-2">
                <button className="btn btn-success col-12 succeed" disabled>
                  PICKED UP
                </button>
              </div>) : (<div className="box shadow-sm bg-light mb-2">
                <button onClick={()=> updateStatus('picked_up')} className="btn btn-success col-12 succeed" >
                  PICKED UP
                </button>
              </div>)}
			  {orderdata[0]?.storestatus === 'STORE_READY_FOR_PICKUP' || orderdata[0]?.storestatus === 'STORE_COMPLETED' ? (<div className="box shadow-sm bg-light mb-2">
                <button className="btn btn-warning col-12 warned" disabled>
                  READY FOR PICKUP
                </button>
              </div>) : (<div className="box shadow-sm bg-light mb-2">
                <button onClick={()=> updateStatus('ready')} className="btn btn-warning col-12 warned" >
                  READY FOR PICKUP
                </button>
              </div>)}
			  {orderdata[0]?.storestatus === 'COOKING' || orderdata[0]?.storestatus === 'STORE_READY_FOR_PICKUP' || orderdata[0]?.storestatus === 'STORE_COMPLETED' ? (<div className="box shadow-sm bg-light mb-2">
				<button className="btn btn-info col-12 accepted" disabled>
                  COOKING
				</button>
              </div>) : (<div className="box shadow-sm bg-light mb-2">
				<button onClick={()=> updateStatus('cooking')} className="btn btn-info col-12 accepted" >
                  COOKING
				</button>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </section>
	);
};

export default OrderDetail;
