import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Category } from "../../Components";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";

const CategoryScreen = ({pcount}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [updatecategory, setUpdateCategory] = useState(false);
	const merchantURL = utils.MERCHANT_URL;
	const merchantCALL = utils.MERCHANT_CALL;
	
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Categories", title: "Categories" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("useremail");
		const storeId = localStorage.getItem("userstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Manage Item Categories | Restaurant | ErrandLocal";
	}, []);
	
	useEffect(() => {
	  if (email && email !== 'undefined') {
		const interval = setTimeout(() => {
		  fetchStoreCategories(email);
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [email]);
	
	const fetchStoreCategories = async (email) => 
	{
		try{
			const targetURL = await merchantCALL+'?request=store&user='+email;
			const header = await utils.genHeader();
			const res = await fetch(targetURL, 'GET', '', header);
			const data = await res.json();					
			return data;	
		}catch(e){return [];}
	}
	
	const onPostCategory = (param) =>
	{	
		var targeturl = merchantURL,
		token = localStorage.getItem('__el_token'), 
		formdata = new FormData();
		
		Object.entries(param).forEach(entry => {
			var [key, value] = entry;		
			formdata.append(key, value);
		});
		
		var ajax = new XMLHttpRequest();
		ajax.upload.addEventListener("progress", progressHandler, false); 
		ajax.addEventListener("load", completeHandler, false); 
		ajax.addEventListener("error", errorHandler, false); 
		ajax.addEventListener("abort", abortHandler, false); 
		ajax.open("POST", targeturl); 
		ajax.setRequestHeader('Authorization', 'Bearer '+token);
		ajax.send(formdata);
		return false;
	}
		
	const onPostDeleteCategory = async (param) =>
	{	
		try{
			const header = await utils.genHeader();
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
			const data = await res.json();
			if(data.msg === 'Token Updated'){
				localStorage.setItem('__el_token', data?.token);
				message.info('Please resubmit');
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){
				setUpdateCategory(true);
			}else{
				message.error(data.msg);
			}
		}catch(e){}
	}
	
	function progressHandler(event){ 
        //message.info("Uploaded "+event.loaded+" bytes of "+event.total);
		var percent = (event.loaded / event.total) * 100;
		//message.info(Math.round(percent)+"% uploaded... please wait");
	} 
	
	function completeHandler(event){	
		const data = JSON.parse(event.target.response);	
		if(data.msg === 'Successful' || data.msg === 'Success'){
			setUpdateCategory(true);
		}else if(data.msg === 'Token Updated'){
			message.info('Please republish');
			localStorage.setItem('__el_token', data.token);
		}else{
			message.error(data.msg);			
		}
	} 
	
	function errorHandler(event){ 
		message.error('Upload Failed');
	} 
	
	function abortHandler(event){ 
		message.error('Upload Aborted');
	} 
	
	return (
		<Content style={{ margin: "24px 16px 0" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Menu Item Categories</h2>
			<span style={{ opacity: 0.5 }}>List of all store menu items categories.</span>
			<Divider />
			<Category pcount={pcount} onPostCategory={onPostCategory} onPostDeleteCategory={onPostDeleteCategory} update={updatecategory} setUpdate={setUpdateCategory} />
		  </div>
		</Content>
	);
	
};

export default CategoryScreen;
