import React, {useState, useEffect} from "react";
import { Form, Button, notification, Row, Tag, message } from "antd";
import { useNavigate } from "react-router-dom";
import { AddTable } from "./AddTable";
import { utils } from "../../Utils";
import { SaveOutlined, ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { Counter } from "../../Components";

const mockData = {
  itemData: [
    {
      category: "",
      file: "",
      name: "",
      description: "",
      price: 0,
      info: [],
    }
  ]
};

//{ value: 'Sample Category', label: 'Sample Category' },
//const categories = [];

const AddItem = ({pcount, params, loaded}) => {
  const navigate = useNavigate();
  const [data, setData] = useState(mockData);
  const [dataLevel, setDataLevel] = useState(params?.itemCategoryOptions ?? []);
  const [info, setInfo] = useState([]);
  const [email, setEmail] = useState('');
  const [storeId, setStoreId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');
  const [form] = Form.useForm();
  
  useEffect(() => 
  {	
	const email = localStorage.getItem("useremail");
	const storeId = localStorage.getItem("userstoreId");
	setEmail(email ?? '');
	setStoreId(storeId ?? '');
	// eslint-disable-next-line
  }, []);

    
  const onFinish = (values) => {
	values.itemData[0].info = info;	
	values.itemData[0].file = fileName;
    setData(values);
	
	let output = {
		'rtype': 'addproduct',
		'mainStoreId': storeId,
		'email': email,
		'productCategory': values.itemData[0].category,
		'productName': values.itemData[0].name,		
		'productImage': values.itemData[0].file,
		'productDescription': values.itemData[0].description,
		'productPrice': values.itemData[0].price,
		'productData': values.itemData[0].info		
	}
	
	
		
	let targeturl = utils.MERCHANT_URL, 
	token = localStorage.getItem('__el_token'), 
	formdata = new FormData();
	
	fileList.forEach(function(file, i)
	{
		formdata.append("file"+i, file);
	});
	
	
	Object.entries(output).forEach(entry => {
		var [key, value] = entry;
		if(key === 'productData'){
			formdata.append(key, JSON.stringify(value));
		}else{
			formdata.append(key, value);
		}
	});
		
	var ajax = new XMLHttpRequest();
	ajax.upload.addEventListener("progress", progressHandler, false); 
	ajax.addEventListener("load", completeHandler, false); 
	ajax.addEventListener("error", errorHandler, false); 
	ajax.addEventListener("abort", abortHandler, false); 
	ajax.open("POST", targeturl); 
	ajax.setRequestHeader('Authorization', 'Bearer '+token);
	ajax.send(formdata);
	return false;
    
  };
  
  const onReset = async () => {
	await setData(mockData);
	await setDataLevel(dataLevel);
	await setInfo([]);
    form.resetFields();
	await setData(mockData);
	await setDataLevel(dataLevel);
	await setInfo([]);
  };

  const moveIt = async (entries) => {
	await setInfo(entries);	
  };
  
  const getFile = async (file) => {
	if(!file){
		await setFileName('');
		await setFileList([]);
		mockData.itemData[0].file = 'File2.jpg';
		await setData(mockData);		
	}else{
		await setFileList([file]);
		await setFileName(file.name);
		mockData.itemData[0].file = file.name;		
		data.itemData[0].file = file.name;
		await setData(mockData);
		await setData(data);
	}	
  };
  
    function progressHandler(event){ 
        //message.info("Uploaded "+event.loaded+" bytes of "+event.total);
		var percent = (event.loaded / event.total) * 100;
		//message.info(Math.round(percent)+"% uploaded... please wait");
	} 
	function completeHandler(event){
		console.log(event.target.response);
		const data = JSON.parse(event.target.response);	
		
		if(data.msg === 'Successful'){
			message.success(data.msg);
		}else if(data.msg === 'Token Updated'){
			message.info('Please republish');
			localStorage.setItem('__el_token', data.token);
		}else{
			message.error(data.msg);			
		}
	} 
	function errorHandler(event){ 
		message.error('Upload Failed');
	} 
	function abortHandler(event){ 
		message.error('Upload Aborted');
	} 

    

  return (
  <section className="content-main">
	<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
		<div className="content-header1">			
			<div><div onClick={()=> navigate("/")} className="btn btn-primary">Back to dashboard</div></div>
        </div>
		
		<Counter pcount={pcount} />
	</div>
    <Form
      form={form}
      name="dynamic_form_item"
      onFinish={onFinish}
      initialValues={data}
    >
	{dataLevel.length >= 0 && loaded && (<Form.List name="itemData">
        {(itemData, { add, remove }) => (
          <AddTable
			data={data}
			size={`large`}
			categories={dataLevel}
            form={form}
            itemData={itemData}
            add={add}
            remove={remove}
			moveIt={(val)=> moveIt(val)}
			getFile={(file)=> getFile(file)}
          />
        )}
	</Form.List>)}
      <br />
      <Row>
        <Form.Item>
          <Button 
			type="default" 
			onClick={onReset} 
			icon={<ReloadOutlined />} 
			size={`large`}
		  >
            Reset
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
			className="btn-primary"
            icon={<SaveOutlined />}
            style={{ marginLeft: 8 }}
			size={`large`}
          >
            Publish
          </Button>
        </Form.Item>
      </Row>
    </Form>
  </section>
  );
};

export default AddItem;
